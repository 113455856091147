.loading-spinner {
  position : absolute;
  top      : 50%;
  left     : 50%;
  -webkit-transform : translateX(-50%) translateY(-50%);
  -moz-transform : translateX(-50%) translateY(-50%);
  transform : translateX(-50%) translateY(-50%); }


.dot {
  background    : black;
  border-radius : 100%;
  color         : white;
  height        : 8px;
  line-height   : 8px;
  text-align    : center;
  width         : 8px; }


.dotOne {
  -webkit-animation : dotOneKeyframes 5s ease  infinite;
  background        : #2951AA;
  position          : absolute;
  top               : 0;
  left              : 0; }


.dotTwo {
  -webkit-animation : dotTwoKeyframes 5s ease 0.4166666666666s infinite;
  background        : #2951AA;
  position          : absolute;
  top               : 0;
  left              : 14px; }


.dotThree {
  -webkit-animation : dotThreeKeyframes 5s ease 0.83333333333s infinite;
  background        : #4AD6AE;
  position          : absolute;
  top               : 14px;
  left              : 14px; }


@-webkit-keyframes dotOneKeyframes {
  0% {
    top  : 0;
    left : 0; }

  8.3333333333% {
    top  : 14px;
    left : 0; }


  25% {
    top  : 14px;
    left : 0; }

  33.3333333333% {
    top  : 14px;
    left : 14px; }


  50% {
    top  : 14px;
    left : 14px; }

  58.3333333333% {
    top  : 0;
    left : 14px; }


  75% {
    top  : 0;
    left : 14px; }

  83.3333333333% {
    top  : 0;
    left : 0; } }





@-webkit-keyframes dotTwoKeyframes {
  0% {
    top  : 0;
    left : 14px; }

  8.3333333333% {
    top  : 0;
    left : 0; }


  25% {
    top  : 0;
    left : 0; }

  33.3333333333% {
    top  : 14px;
    left : 0; }


  50% {
    top  : 14px;
    left : 0; }

  58.3333333333% {
    top  : 14px;
    left : 14px; }


  75% {
    top  : 14px;
    left : 14px; }

  83.3333333333% {
    top  : 0;
    left : 14px; } }





@-webkit-keyframes dotThreeKeyframes {
  0% {
    top  : 14px;
    left : 14px; }

  8.3333333333% {
    top  : 0;
    left : 14px; }


  25% {
    top  : 0;
    left : 14px; }

  33.3333333333% {
    top  : 0;
    left : 0; }


  50% {
    top  : 0;
    left : 0; }

  58.3333333333% {
    top  : 14px;
    left : 0; }


  75% {
    top  : 14px;
    left : 0; }

  83.3333333333% {
    top  : 14px;
    left : 14px; } }
