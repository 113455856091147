body {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1920px;
	min-width: 320px;
	min-height: 100vh;
	height: 100%;
	background-color: $theme-gray-light;
	margin: 0 auto !important; }

.loading-page {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	background-color: white;
	z-index: 9999;

	&.hidden {
		display: none; } }


.overlay-mask {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0,0,0,.7);
	z-index: 1000;

	&.active {
		display: block; } }


nav.desktop-nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 1920px;
	height: 100px;
	margin: 0 auto;
	background: linear-gradient(rgba(5,64,64, .7), rgba(0,128,128, .5));
	z-index: 10;

	.desktop-nav-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 1110px;
		height: 100px;
		margin: 0 auto;

		.logo-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 110px;
			height: 100px;
			background-color: $white;

			a {
				padding: 7px 4px 7px 5px;

				img {
					width: 99px;
					height: 86px; } } }

		.company-full-name {
			position: absolute;
			top: 0;
			left: 120px;
			width: 275px;
			height: 60px;
			background-color: rgba(0,0,0,.6);
			padding: 5px 8px;
			color: $white;
			font-weight: bold;
			line-height: 1.5em; }

		.member-log-in {
			position: absolute;
			top: 0;
			right: 0;
			width: 170px;
			height: 40px;
			padding: 10px;
			font-size: 18px;
			line-height: 29px;
			font-family: $bold-theme;
			text-shadow: $text-shadow-nav;
			text-align: right;
			color: $white; }

		.logout-button {
			position: absolute;
			top: 0;
			right: 0;
			width: 300px;
			height: 40px;
			padding: 7px 0;
			font-size: 18px;
			line-height: 29px;
			font-family: $bold-theme;
			text-shadow: $text-shadow-nav;
			text-align: right;

			a {
				margin-right: 30px;
				color: $white; }

			&:after {
				content: "";
				width: 0;
				height: 0;
				position: absolute;
				bottom: 14px;
				right: 4px;
				border-width: 6px 6px 0 6px;
				border-style: solid;
				border-color: $white transparent; }

			a[href="/user/logout"] {
				display: block;
				width: 100%;
				height: 40px;
				padding: 10px; } }

		.logout-button:hover .login-sub-menu {
			opacity: 1;
			pointer-events: auto;
			transition: opacity .5s .1s; }

		.login-sub-menu {
			opacity: 0;
			position: absolute;
			top: 40px;
			right: 0;
			width: 155px;
			height: 90px;
			padding: 0;
			border-radius: 3px;
			background-color: rgba(0,0,0,1);
			transition: opacity .5s .15s;
			pointer-events: none;
			text-align: center;
			z-index: 1;

			li {
				width: 100%;
				height: 45px;
				margin: 0;
				font-size: .9em;

				&:first-of-type {
					border-radius: 3px 3px 0 0; }

				&:last-of-type {
					border-radius: 0 0 3px 3px;
					background-color: $theme-blue; }

				a {
					display: inline-block;
					width: 100%;
					height: 45px;
					color: $white;
					padding: 14px 0;
					font-family: $bold-theme; } } }


		.navigation {
			position: absolute;
			display: inline-block;
			top: 62px;
			right: 0;
			margin: 0 10px;
			font-weight: bold;

			li {
				position: relative;
				display: inline-block;
				height: 24px;
				margin-left: 40px;
				color: $white;

				a {
					display: inline-block;
					color: $white;
					height: 21px;
					font-family: $bold-theme;
					font-size: 18px;
					text-shadow: $text-shadow-nav;
					transition: color .2s;

					&:hover {
						color: #50e3c2; } } }


			.dd {
				position: relative;

				.sub-menu {
					opacity: 0;
					position: absolute;
					top: 30px;
					right: 0;
					width: 192px;
					padding: 0;
					transition: opacity .5s .15s;
					pointer-events: none;
					text-align: center;

					li {
						width: 100%;
						height: 50px;
						margin: 0;
						background-color: rgba(0,0,0,.7);
						transition: background-color .2s;

						&:first-of-type {
							border-radius: 5px 5px 0 0; }
						&:last-of-type {
							border-radius: 0 0 5px 5px; }

						&:hover {
							background-color: rgba(0,0,0,.8); }

						a {
							display: inline-block;
							width: 100%;
							height: 50px;
							padding: 15px;
							font-family: $primary-style;
							font-size: 13px;
							font-weight: lighter !important; } } }


				.sub-menu-2 {
					height: 97px; } }

			.dd>a {
				width: 100%;
				height: 50px;

				&:hover {
					color: $white; } }


			.dd:hover > .sub-menu {
				opacity: 1;
				pointer-events: auto;
				transition: opacity .5s .1s; }


			.dd-arrow-down {
				display: inline-block;
				top: 17px;
				width: 15px;
				height: 25px;
				margin: 0 auto; } } } }



.page-container {
	flex: 1 0 auto; }


footer {
	width: 100%;
	height: 250px;
	background-color: $theme-green;
	z-index: 9;

	.footer-wrapper {
		position: relative;
		width: 1110px;
		height: 240px;
		left: 0;
		right: 0;
		margin: 0 auto;

		img {
			position: absolute;
			top: -20px;
			left: 0; }

		.contact-info {
			position: absolute;
			top: 50px;
			left: 600px;

			div {
				display: inline-block;
				margin-right: 60px;

				span, p, h5 {
					color: $white;
					font-size: 13px;
					line-height: 1.3em; }

				h5 {
					font-weight: bold;
					margin-bottom: 1em; } } }

		.fontech-logo {
				display: flex;
				align-items: flex-end;
				position: absolute;
				bottom: 30px;
				left: 0;

				span {
					color: white !important;
					font-size: 12px; }

				img {
					position: relative;
					top: auto;
					left: auto;
					width: 156px;
					margin-left: 16px; } } } }


//mobile nav
nav.mobile-nav {
	display: none;

	.header-strip {
		position: fixed;
		left: 0;
		right: 0;
		width: 100%;
		height: 40px;
		margin: 0 auto;
		padding: 0;
		text-align: center;
		background: rgba(255,255,255,.9);
		z-index: 11;
		box-shadow: $box-shadow;

		h1.page-name {
			margin: 5px 10px 0 0;
			font-size: 18px;
			line-height: 30px; } }



	// animated hamburger to X menu icon
	$button-size: 30px;
	$transition: .3s;
	$color: white;

	.lines-button {
		position: fixed;
		top: 0;
		right: 0;
		z-index: 12;
		display: inline-block;
		width: 50px;
		height: 50px;
		padding: 8px 10px 15px;
		transition: .3s;
		cursor: pointer;
		user-select: none;
		background-color: $theme-blue;
		appearance: none;
		border: none;
		opacity: 1;

		&:focus {
			outline: 0; } }


	@mixin line {
		display: inline-block;
		width: $button-size;
		height: 3px;
		background: $color;
		transition: $transition;
		border-radius: 3px; }


	.lines {
		@include line; //create middle line
		position: relative;

		/*create the upper and lower lines as pseudo-elements of the middle line*/
		&:before,
		&:after {
			@include line;
			position: absolute;
			left: 0;
			content: '';
			transform-origin: $button-size/14 center; }

		&:before {
			top: 8px; }
		&:after {
			top: -8px; } }

	//animating
	.lines-button.x.close .lines {
		/*hide the middle line*/
		background: transparent;

		/*overlay the lines by setting both their top values to 0*/
		&:before,
		&:after {
			transform-origin: 50% 50%;
			top: 0;
			width: $button-size; }

		// rotate the lines to form the x shape
		&:before {
			transform: rotate3d(0,0,1,45deg); }
		&:after {
			transform: rotate3d(0,0,1,-45deg); } }
	// end of animated hamburger icon


	// hides the menu
	.hidden-mobile {
		top: -670px !important; }

	.navigation-mobile {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		padding: 55px 0 30px;
		text-align: center;
		background-color: rgba(47,91,189, .95);
		box-shadow: $box-shadow;
		z-index: 10;

		li {
			display: inline-block;
			width: 100%;

			a {
				display: inline-block;
				width: 100%;
				height: 50px;
				padding: 12px;
				color: white;
				font-size: 20px;
				transition: background-color .1s ease-in; }

			.log-in-btn {
				width: 260px;
				height: 40px;
				margin: 15px auto 0;
				padding: 8px;
				font-size: 16px;
				background-color: $theme-green; } }


		.mobile-dropdown-wrapper {
			display: inline-block;
			width: 100%;
			overflow: hidden;
			transition: height .3s, opacity .3s;

			li {
				height: 50px;

				a {
					font-size: 18px; } }

			&.submenu-1 {
				height: 210px; }
			&.submenu-2 {
				height: 160px; }
			&.submenu-3 {
				height: 165px; } }


		.expand-menu {
			position: relative;
			height: 50px;

			&:after {
				content: "";
				width: 0;
				height: 0;
				position: absolute;
				top: 23px;
				right: calc(100%/2 - 50px);
				border-width: 6px 6px 0 6px;
				border-style: solid;
				border-color: $white transparent; }

			&.expand-2 {
				margin-top: -17px; }

			&.expand-2:after {
				right: calc(100%/2 - 85px); }

			&.expand-3:after {
				right: calc(100%/2 - 70px); } }

		.menu-collapse {
			height: 0 !important;
			opacity: 0 !important; }

		.negative-margin {
			margin-top: -17px; } }//fixes vertical space collapsed menu occupies


	.fade-in {
		transition: top .7s cubic-bezier(.26,.96,.52,.95); }
	.fade-out {
		transition: top 1s cubic-bezier(.69,0,.28,.98); } }






//BREAKPOINTS
@media all and (max-width: $tablet) {
	nav.desktop-nav {
		display: none; }

	nav.mobile-nav {
		display: block;

		.header-strip {
			height: 50px;

			h1.page-name {
				font-size: 20px;
				line-height: 40px; } }

		.hamburger-menu {
			width: 50px;
			height: 50px;
			padding: 10px 13px;
			font-size: 28px; }

		.fa-times {
			padding: 10px 13px;
			font-size: 28px; }

		.navigation-mobile {
			padding: 65px 0 30px; } }


	footer {
		height: 300px;

		.footer-wrapper {
			position: relative;
			width: 100%;
			height: 300px;
			left: 0;
			right: 0;
			margin: 0 auto;

			img {
				position: absolute;
				top: -40px;
				left: 20px; }

			.contact-info {
				position: absolute;
				top: 100px;
				left: 0;
				right: 0;
				width: 450px;
				margin-left: auto;

				div {
					margin: 0;

					&:first-of-type {
						margin-right: 50px; } } }
			.fontech-logo {
				left: 20px;
				bottom: 32px;

				img {
					width: 132px; } } } } }






@media all and (max-width: $mobile) {
	nav.desktop-nav {
		display: none; }


	// .page-container
	// 	background-color: $white


	footer {
		width: 100%;
		height: 500px;

		.footer-wrapper {
			position: relative;
			width: 100%;
			height: 500px;

			img {
				top: -25px;
				left: 0;
				right: 0;
				width: 129px;
				height: 49px;
				margin: 0 auto; }

			.contact-info {
				top: 80px;
				left: 0;
				right: 0;
				width: 250px;
				margin: 0 auto;
				text-align: center;

				div:first-of-type {
					margin-right: 0; }

				span, p, h5 {
					font-size: 1em; }

				h5 {
					margin-bottom: 1.1em; } }

			.contact-info>div {
				margin-bottom: 40px;
				margin-right: 0; }

			.fontech-logo {
				bottom: 24px;
				left: 50% !important;
				transform: translateX(-50%);

				img {
					width: 142px; } } } } }

