

// colors
$theme-green: #009B9D;
$theme-green-light: #4AD6AE;
$theme-teal: #23b49f;
$theme-blue: #2A51AA;
$theme-blue-dark: #2951aa;
$theme-gray: #5D5D5D;
$theme-gray-medium: #8A8A8A;
$theme-gray-light: #F3F1F0;
$theme-black: black;
$white: #FFF;
$btn-inactive: #A2BBB4;



// font stack
@font-face {
	font-family: 'OpenSans';
	src: url('/assets/fonts/OpenSans-Regular.ttf');
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'OpenSans-Semibold';
	src: url('/assets/fonts/OpenSans-Semibold.ttf');
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'OpenSans-Bold';
	src: url('/assets/fonts/OpenSans-Bold.ttf');
	font-weight: normal;
	font-style: normal; }

@font-face {
	font-family: 'Noto';
	src: url('/assets/fonts/NotoSans-Regular.ttf');
	font-weight: normal;
	font-style: normal; }
@font-face {
	font-family: 'Noto';
	src: url('/assets/fonts/NotoSans-Bold.ttf');
	font-weight: bold;
	font-style: normal; }
@font-face {
	font-family: 'fa';
	src: url('/assets/fonts/fontawesome-webfont.woff');
	src: url('/assets/fonts/fontawesome-webfont.eot');
	src: url('/assets/fonts/fontawesome-webfont.ttf');
	font-weight: normal;
	font-style: normal; }

$primary-style: "OpenSans", "Noto", "lato", "Helvetica","AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "Microsoft JhengHei", sans-serif;
$secondary-style: "NettoOT", Georgia, Cambria, 'Times New Roman', Times, LiSong Pro, serif;
$bold-theme: "OpenSans-Semibold", "OpenSans", "Noto", "lato", "Helvetica", "Microsoft JhengHei", sans-serif;



// image mixin
@mixin image-formating($image) {
	background-image: url($image);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 0%; }



// shadows
$box-shadow: 1px 1px 6px 2px rgba(0,0,0, .2);
$inset-shadow: inset 0 0 0 1000px rgba(0,0,0,.2); // darkening of pictures
$text-shadow: 2px 4px 6px rgba(0,0,0,.7);
$text-shadow-nav: 0 1px 3px rgba(0, 0, 0, 0.5);


//breakpoints
$mobile: 720px;
$tablet: 1109px;
