.member-directory-hero {
	@include image-formating('/assets/img/membership-hero.png'); }


.main-content.no-bg-color {
	background-color: $theme-gray-light !important; }

.main-content {
	.search-box {
		float: left;
		width: 315px;
		height: 670px;
		background-color: $theme-green-light;
		padding: 55px 38px;

		h4 {
			color: $white;
			margin-bottom: 30px; }


		.dropdown-wrapper {
			position: relative;
			width: 240px;
			height: 44px;
			margin-bottom: 20px;

			//small arrow
			&:after {
				content: "";
				width: 0;
				height: 0;
				position: absolute;
				right: 15px;
				top: 25px;
				margin-top: -6px;
				border-width: 6px 6px 0 6px;
				border-style: solid;
				border-color: #777777 transparent; } }

		.dropdown {
			width: 240px;
			height: 44px;
			padding: 4px 9px;
			border: solid 1px #ced1d2;
			border-radius: 2px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			cursor: pointer;
			outline: none;
			font-size: 14px;
			color: #777777; }

		.search-btn {
			width: 165px;
			padding: 9px;
			background: $theme-blue;
			color: $white;
			border: none;
			border-radius: 2px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			cursor: pointer;
			outline: none;
			margin-top: 50px; }

		.content-search {
			width: 240px;
			height: 44px;
			padding: 12px;
			font-size: .9em;
 }			// color: $theme-gray


		// .search-btn
		// 	display: block
		// 	background-color: $theme-blue
		// 	margin-top: 50px

		// 	&:hover
 }		// 		color: $white



	.member-directory-content-wrapper {
		float: left;
		width: 795px;
		height: 1550px;
		background-color: $white;
		margin-bottom: 20px;

		.content-buttons {
			width: 480px;
			display: block;

			.content-btn {
				width: 220px;
				transition: background-color .2s; } }


		.member-display {
			width: 100%;
			margin: 100px auto 50px;
			padding: 10px 60px;

			.member-box {
				width: 100%;
				height: 230px;
				margin-bottom: 30px;
				border-bottom: solid 2px $theme-gray-light;

				.img-wrapper {
					position: relative;
					width: 35%;
					height: 220px;
					text-align: center;
					float: left;

					img {
						display: inline-block;
						position: absolute;
						top: 20%;
						left: 0;
						right: 0;
						max-height: 80px;
						margin: 0 auto;
						width: 85%; } }

				.info-wrapper {
					position: relative;
					float: left;
					width: 65%;
					height: 230px; }

				h3 {
					color: $theme-blue;
					font-weight: bold;
					font-size: 24px; }

				.full-name {
					display: block;
					color: $theme-black;
					font-size: 16px;
					line-height: 20px;
					margin-bottom: .7em; }

				.info {
					p, span {
						color: $theme-black;
						font-size: .8rem; }

					a {
						color: $theme-green; } }

				.view-detail {
					position: absolute;
					right: 0;
					bottom: 15px;
					width: 120px;
					height: 35px;
					padding: 7px;
					background-color: $theme-blue;
					font-size: 13px; } } } }

	.pagination-nav {
		text-align: center; } }



//BREAKPOINTS
@media all and (max-width: $tablet) {
	.main-content {
		.search-box {
			position: absolute;
			top: -200px;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 580px;
			height: 100px;
			padding: 0;
			background-color: transparent;

			h4 {
				margin-bottom: 5px; }

			.search-input {
				position: absolute;
				width: 580px; }

			.content-search,
			.dropdown {
				top: 0;
				position: absolute;
				background: rgba(255,255,255,.8);
				border-radius: 2px;
				border: none;

				&::-webkit-input-placeholder {
					color: $theme-gray;
					font-size: .9em; }
				&:-moz-placeholder {
					color: $theme-gray;
					opacity:  1;
					font-size: .9em; }
				&::-moz-placeholder {
					color: $theme-gray;
					opacity:  1;
					font-size: .9em; }
				&:-ms-input-placeholder {
					color: $theme-gray;
					font-size: .9em; } }

			.content-search {
				left: 0;
				width: 210px;
				height: 44px;
				padding: 12px; }

			.dropdown-wrapper {
				top: 0;
				left: 230px;
				width: 200px;

				.dropdown {
					width: 200px;
					padding: 4px 12px;
					font-size: 13px; } }


			.search-btn {
				position: absolute;
				top:  0;
				right: 0;
				width: 120px;
				height: 44px;
				display: block;
				background-color: $theme-green-light;
				margin-top: 0;
				padding: 10px;
				border-radius: 2px; } }


		.member-directory-content-wrapper {
			float: none;
			width: 100%;

			.content-buttons {
				left: 50%;
				margin-left: -239px; } } } }



@media all and (max-width: $mobile) {
	.member-directory-hero {
		height: 350px; }

	.main-content {
		margin: 0 auto 50px;

		.search-box {
			top: -220px;
			width: 220px;
			height: 200px;

			h4 {
				display: none; }

			.dropdown-wrapper {
				top: 60px;
				left: 0;
				width: 220px;

				.dropdown {
					width: 220px; } }

			.content-search {
				top: 0;
				left: 0;
				width: 220px; }

			.search-btn {
				top: 130px;
				left: 0;
				width: 220px; } }



		.member-directory-content-wrapper {
			width: 100%;
			height: 2200px;
			margin-bottom: 0;
			margin-left: 0;

			.content-buttons {
				top: 160px;
				left: 0;
				width: 309px;
				display: none;

				.content-btn {
					position: absolute;
					width: 152px;
					height: 40px;
					padding: 10px 2px;
					background-color: #A2BBB4;
					transition: background-color .2s;
					font-size: .9em; }

				.content-btn-1 {
					top: 0;
					left: 0; }

				.content-btn-2 {
					top: 0;
					left: 157px; }

				.active {
					background-color: $theme-green-light; } }


			.member-display {
				width: 100%;
				margin: 0 auto 10px;
				padding: 30px 20px 10px;

				.member-box {
					position: relative;
					width: 100%;
					min-height: 380px;

					.img-wrapper {
						float: none;
						// position: absolute
						// top: 0
						// left: 0
						// right: 0
						width: 100%;
						height: 190px;
						margin: 0 auto;

						img {
							top: 5%;
							width: 85%; } }

					.info-wrapper {
						// position: absolute
						float: none;
						// top: 200px
						// left: 0
						// right: 0
						width: 300px;
						height: 250px;
						margin: 0 auto;

						.full-name {
							line-height: 1.3em; }

						.view-detail {
							position: relative;
							// left: 0
							// right: 0
							bottom: 0;
							margin: 10px auto 20px auto; } } } } } } }

















