.gallery-detail-wrap {
	width: 765px;
	margin: 90px auto 100px;
	min-height: 100px;
	overflow: hidden;
	h1 {
		font-size: 24px;
		font-weight: 600;
		line-height: 1.5;
		color: #009b9d; }
	.time {
		font-size: 16px;
		font-weight: 600;
		line-height: 1.5;
		color: #2951aa;
		margin-top: 24px;
		position: relative;
		&:after {
			content: "";
			width: 765px;
			height: 1px;
			display: block;
			position: absolute;
			background-color: #dfe9e9;
			top: 30px;
			left: -2px; } }

	.location {
		font-size: 14px;
		line-height: 1.36;
		text-align: left;
		color: #2951aa;
		margin-top: 29px;
		position: relative;
		margin: 29px 0px 0px 25px;
		.fa {
			color: #4ad6ae;
			font-size: 24px;
			position: absolute;
			top: -4px;
			left: -25px; } }

	.intro {
		font-size: 14px;
		line-height: 1.57;
		color: #777777;
		margin-top: 28px;
		word-break: keep-all; }

	.photos-wrap {
		width: 100%;
		min-height: 100px;
		overflow: hidden;
		margin: 60px auto 0px;
		.single-photo-wrap {
			width: 240px;
			height: 240px;
			margin: 0px 20px 20px 0px;
			display: block;
			float: left;

			&:nth-child(3n) {
				margin: 0px 0px 20px 0px; } }


		.single-photo {
			width: 240px;
			height: 240px;
			overflow: hidden;
			position: relative;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			&:before {
				content: "";
				position: absolute;
				top: 0px;
				left: 0px;
				width: 240px;
				height: 240px;
				opacity: 0;
				display: block;
				mix-blend-mode: multiply;
				color: white;
				@include smooth(0.2s, linear);
				background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(140, 140, 140, 0.6) 1%, rgba(0, 0, 0, 0.6)); }
			&:after {
				content: "View Fullsize";
				position: absolute;
				top: 0px;
				left: 0px;
				width: 240px;
				height: 240px;
				line-height: 240px;
				text-align: center;
				font-weight: 600;
				color: white;
				opacity: 0;
				@include smooth(0.2s, linear); }

			&:hover {
				&:before {
					opacity: 1; }
				&:after {
					opacity: 1; } }
			img {
				max-width: 500px;
				height: auto;
				min-width: 240px;
				min-height: 240px;
				align: middle; } } } }


@media all and (max-width: 850px) {
	.gallery-detail-wrap {
		width: 595px;
		.time {
			&:after {
				width: 605px; } }
		.photos-wrap {
			.single-photo-wrap {
				width: 186px;
				height: 186px;
				margin: 0px 15px 15px 0px;
				display: block;
				float: left;

				&:nth-child(3n) {
					margin: 0px 0px 15px 0px; } }
			.single-photo {
				max-width: 186px;
				height: 186px;
				&:before {
					width: 186px;
					height: 186px; }
				&:after {
					width: 186px;
					height: 186px;
					line-height: 186px; } }
			img {
				max-width: 350px;
				height: autos;
				min-width: 186px;
				min-height: 186px; } } } }

@media all and (max-width: 600px) {
	.gallery-detail-wrap {
		width: 282px;
		.time {
			&:after {
				width: 306px; } }
		.location {
			margin: 30px 0px 0px 30px;
			.fa {
				top: 3px;
				font-size: 30px;
				left: -30px; } }
		.photos-wrap {
			.single-photo-wrap {
				width: 133px;
				height: 133px;
				margin: 0px 14px 14px 0px;
				display: block;
				overflow: hidden;
				float: left;

				&:nth-child(3n) {
					margin: 0px 14px 14px 0px; }
				&:nth-child(2n) {
					margin: 0px 0px 14px 0px !important; } }

			.single-photo {
				width: 133px;
				height: 133px;
				&:before {
					width: 133px;
					height: 133px; }
				&:after {
					width: 133px;
					height: 133px;
					line-height: 133px; } }
			img {
				max-width: 260px;
				height: auto;
				min-width: 133px;
				min-height: 133px; } } } }

