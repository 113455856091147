.events-photo-wrap {
	width: 1000px;
	min-height: 100px;
	height: auto;
	overflow: visible;
	margin: 90px auto 177px;

	.single-event {
		width: 300px;
		height: 360px;
		float: left;
		overflow: visible;
		margin: 0px 15px;

		.img-wrap {
			width: 290px;
			height: 163px;
			position: relative;
			overflow: visible;
			&:after {
				content: "";
				width: 290px;
				height: 163px;
				background-color: #2a51aa;
				top: 10px;
				left: 10px;
				position: absolute;
				display: block;
				z-index: 0;
				@include smooth(0.2s, linear); }

			img {
				position: absolute;
				top: 0px;
				left: 0px;
				width: 290px;
				height: 163px;
				display: block;
				z-index: 1;
				@include smooth(0.2s, linear); }

			&:hover {
				// img
				// 	top: -10px
				// 	left: -10px
				&:after {
					top: 0px;
					left: 0px; } } }


		.title {
			font-size: 20px;
			font-weight: 600;
			line-height: 1.3;
			color: #009b9d;
			margin-top: 35px; }

		.time {
			font-size: 16px;
			font-weight: 600;
			line-height: 1.19;
			color: #2951aa;
			margin-top: 14px; } } }
@media all and (max-width: 1050px) {
	.events-photo-wrap {
		width: 670px; } }
@media all and (max-width: 655px) {
	.events-photo-wrap {
		width: 95%;
		.single-event {
			float: initial;
			margin: 0px auto; } } }


