.association-member-detail-hero {
	@include image-formating('/assets/img/membership-hero.png'); }


.asc-detail-content-wrapper {
	width: 100%;
	padding: 20px 165px 100px;

	section {
		margin: 50px 0;

		h3 {
			color: $theme-blue;
			margin-bottom: 35px; }

		h4 {
			color: $theme-green;
			margin-bottom: 25px; }

		p {
			margin-bottom: 40px;
			font-size: .9em; }

		.info {
			position: relative;

			.details {
				width: 70%;

				p {
					font-size: .9em;
					line-height: 1.4em;

					span {
						line-height: 1.4em;
						font-size: .9em; }

					img {
						position: absolute;
						top: 0;
						right: 0; } } }

			.history p {
				line-height: 1.4em; } } }


	.pictures-mobile {
		display: none; }

	.pictures-desktop {
		padding: 0;

		.pictures {
			text-align: center;
			img:nth-of-type(2) {
				margin: 0 10px; } } }

	.history p {
		color: $theme-blue; }

	.back-to-btn {
		display: none; } }


//BREAKPOINTS
@media all and (max-width: $tablet) {
	.asc-detail-content-wrapper {
		width: 100%;
		padding: 20px 50px 100px; } }



@media all and (max-width: $mobile) {
	.association-member-detail-hero {
		height: 200px; }

	.asc-detail-content-wrapper {
		width: 100%;
		padding: 20px 20px 10px;

		section {
			margin: 30px 0;
			padding: 0 10px;

			&:first-of-type {
				margin-top: 100px; }
			&:last-of-type {
				margin-bottom: 60px; }

			h3 {
				color: $theme-blue;
				margin-bottom: 35px;
				font-size: 22px;
				line-height: 25px;
				font-weight: bold; }

			h4 {
				color: $theme-green;
				margin-bottom: 10px;
				font-size: 15px;
				font-weight: bold; }

			p {
				margin-bottom: 40px;
				font-size: .9em; }

			.info {
				.details p {
					font-size: .9em;
					line-height: 1.4em;
					span {
						line-height: 1.4em;
						font-size: .9em; }

					img {
						position: absolute;
						top: -220px;
						left: 0;
						right: 0;
						margin: 0 auto; } }

				.history p {
					line-height: 1.4em; } } }

		.pictures-desktop {
			display: none; }

		.pictures-mobile {
			display: block;
			margin: 0 0 0 -20px;
			padding: 0;

			.carousel {
				width: 100vw;
				height: 200px;
				overflow: hidden; } }

		.back-to-btn {
			display: block;
			margin: 0 auto;
			background-color: $theme-blue; }


		.history p {
			color: $theme-blue; } } }









