

.about-us-hero {
	@include image-formating('/assets/img/about-us-hero.png'); }


.about-content-wrapper {
	width: 100%;
	padding: 120px 180px;

	p {
		font-size: 16px; }

	.about-header {
		color: $theme-blue;
		margin-bottom: 25px; }

	p.history {
		color: $theme-green;
		margin-bottom: 69px; }

	.president-photo {
		float: left;
		width: 160px;
		margin-bottom: 20px;

		p {
			font-weight: bold; }
		img {
			max-width: 160px; } }

	p.president-message {
		float: right;
		width: calc(100% - 200px);
		margin-bottom: 50px; }

	img.president-signature {
		float: none;
		margin-left: 200px;
		margin-bottom: 75px; }

	p.vision {
		margin-bottom: 60px; }

	ul.objectives {
		margin-bottom: 50px;

		li {
			position: relative;
			line-height: 1.8em;
			padding-left: 20px;

			&:before {
				position: absolute;
				left: -1px;
				content: "\2022";
				font-size: 1.2em; } } }

	.company-logo {
		float: left;
		margin: 30px 0 0 15px; }

	.logo-description {
		float: left;
		width: 560px;
		margin-left: 40px; } }



//BREAKPOINTS
@media all and (max-width: $tablet) {
	.about-content-wrapper {
		width: 100%;
		padding: 60px;

		p.history {
			margin-bottom: 40px; }

		p.vision {
			margin-bottom: 50px; }

		.logo-description {
			width: 65%; } } }




@media all and (max-width: $mobile) {
	.about-us-hero {
		height: 200px; }

	.about-content-wrapper {
		padding: 60px 20px 20px;

		.about-header {
			margin-bottom: 25px;
			font-size: 21px;
			line-height: 23px; }

		.president-photo {
			float: none; }

		p.president-message {
			float: none;
			width: 100%; }
		img.president-signature {
			margin-left: 0px; }

		p.vision {
			margin-bottom: 30px; }

		ul.objectives {
			margin-bottom: 30px; }

		img.company-logo {
			display: none; }

		.logo-description {
			display: none; } } }














