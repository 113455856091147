.contact-us-hero {
	@include image-formating('/assets/img/membership-hero.png'); }


.contact-us-content-wrapper {
	padding: 50px 190px;

	.contact-form-container {
		width: 730px;
		margin: 50px auto 100px;

		.flexbox-container {
			display: flex;
			justify-content: space-between;

			.flex-item-1 {
				width: 255px; }

			.flex-item-2 {
				width: 445px;

				.btn {
					float: left; }

				.g-recaptcha {
					float: right; } } }

		label {
			position: absolute; }

		legend {
			color: $theme-blue;
			font-size: 2em;
			margin-bottom: 40px; }

		input,
		textarea {
			width: 100%;
			height: 36px;
			font-family: $primary-style;
			color: $theme-black;
			border: solid 1px $theme-blue;
			border-radius: 3px;
			line-height: 1.5em;
			outline: 0;
			margin-bottom: 10px;
			padding: 10px;
			resize: none;
			font-size: .9rem;

			&::-webkit-input-placeholder {
				color: $theme-gray-medium;
				font-family: $primary-style; }
			&:-moz-placeholder {
				color: $theme-gray-medium;
				font-family: $primary-style; }
			&::-moz-placeholder {
				color: $theme-gray-medium;
				font-family: $primary-style; }
			&:-ms-input-placeholder {
				color: $theme-gray-medium;
				font-family: $primary-style; }

			&:focus {
				border: solid 1px $theme-green-light;
				box-shadow: 0 0 1px 1px rgba(74,214,174, .5); } }

		textarea {
			height: 175px; }

		button {
			width: 125px;
			padding: 5px;
			height: 35px;
			border: none;
			border-radius: 3px;
			font-size: 1.1em;
			background-color: $theme-blue; } }


	.address-container {
		width: 730px;
		margin-bottom: 40px;

		.contact-info {
			float: left;
			width: 255px;

			span, p {
				font-size: .95em;
				line-height: 1.3em; }

			h5 {
				color: $theme-green-light;
				font-size: 1em;
				font-weight: bold;
				margin-bottom: 1em; } }

		.google-map-container {
			float: right;
			width: 445px;

			iframe {
				width: 445px;
				height: 230px; } } } }



//BREAKPOINTS
@media all and (max-width: $tablet) {
	.contact-us-content-wrapper {
		padding: 20px;
		.contact-form-container {
			width: 80%;

			.flexbox-container {
				justify-content: flex-start; }

			input {
				width: 95%; }
			textarea {
				width: 100%; }

			.align {
				text-align: right;

				button {
					width: 100px;
					height: 35px;
					padding: 5px;
					font-size: .9em; } } }


		.address-container {
			// width: 600px
			margin: 0 auto 40px;

			.contact-info {
				width: 250px; } } } }



@media all and (max-width: $mobile) {
	.contact-us-hero {
		height: 200px; }

	.contact-us-content-wrapper {
		padding: 50px 20px;

		.contact-form-container {
			width: 100%;
			max-width: 320px;
			margin: 0 auto 50px;

			legend {
				display: none; }

			.flexbox-container {
				flex-direction: column;
				justify-content: center;

				input {
					width: 100%; }

				.flex-item-1 {
					width: 100%; }

				.flex-item-2 {
					width: 100%; } } }


		.address-container {
			width: 280px;
			margin: 0 auto 40px;

			.contact-info {
				float: none;
				width: 280px;
				margin: 0 0 30px 0; }

			.google-map-container {
				float: none;
				width: 280px;

				iframe {
					width: 280px;
					height: 230px; } } } } }
