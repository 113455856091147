// modal login box

.login-modal {
	display: none;
	position: absolute;
	top: 15vh;
	left: 0;
	right: 0;
	width: 520px;
	height: auto;
	margin: 0 auto;
	background-color: $white;
	z-index: 10000;

	&.active {
		display: block; }

	h3 {
		position: absolute;
		top: -50px;
		color: $white; }



	// animated hamburger to X menu icon
	$button-size: 30px;
	$color: white;

	.lines-button-login {
		z-index: 12;
		display: inline-block;
		width: 50px;
		height: 50px;
		padding: 8px 10px 15px;
		cursor: pointer;
		user-select: none;
		appearance: none;
		border: none;
		opacity: 1;

		&:focus {
			outline: 0; } }


	@mixin line {
		display: inline-block;
		width: $button-size;
		height: 3px;
		background: $color;
		border-radius: 3px; }


	.lines {
		@include line; //create middle line
		position: relative;

		/*create the upper and lower lines as pseudo-elements of the middle line*/
		&:before,
		&:after {
			@include line;
			position: absolute;
			left: 0;
			content: '';
			transform-origin: $button-size/14 center; }

		&:before {
			top: 8px; }
		&:after {
			top: -8px; } }

	//animating
	.lines-button-login.x.close .lines {
		/*hide the middle line*/
		background: transparent;

		/*overlay the lines by setting both their top values to 0*/
		&:before,
		&:after {
			transform-origin: 50% 50%;
			top: 0;
			width: $button-size; }

		// rotate the lines to form the x shape
		&:before {
			transform: rotate3d(0,0,1,45deg); }
		&:after {
			transform: rotate3d(0,0,1,-45deg); } }
	// end of animated hamburger icon


	.close-login-modal {
		position: absolute;
		top: -55px;
		right: -10px;
		cursor: pointer; }

	img {
		display: block;
		width: 99px;
		height: 90px;
		margin: 40px auto 0; }

	form {
		width: 100%;
		padding: 45px;

		input {
			width: 100%;
			height: 50px;
			font-family: $primary-style;
			color: $theme-black;
			border: none;
			border-radius: 3px;
			line-height: 1.5em;
			outline: 0;
			margin-bottom: 15px;
			padding: 10px;
			resize: none;
			font-size: .9rem;
			background-color: #eeeeee;

			&::-webkit-input-placeholder {
				color: $theme-gray-medium;
				font-family: $primary-style; }
			&:-moz-placeholder {
				color: $theme-gray-medium;
				font-family: $primary-style; }
			&::-moz-placeholder {
				color: $theme-gray-medium;
				font-family: $primary-style; }
			&:-ms-input-placeholder {
				color: $theme-gray-medium;
				font-family: $primary-style; }

			&:focus {
				border: solid 1px $theme-green-light;
				box-shadow: 0 0 1px 1px rgba(74,214,174, .5); } }

		button {
			width: 100%;
			padding: 12px;
			border: none;
			border-radius: 3px;
			font-size: 1.1em;
			background-color: $theme-green-light;
			color: $white;
			margin: 30px auto 15px; }

		.message {
			text-align: center;

			a {
				display: inline-block;
				color: red;
				padding-left: 5px; } }

		.divide {
			width: calc(100% + 90px);
			border-bottom: solid 3px $theme-gray-light;
			margin: 20px 0 35px -45px; } }


	.register-form {
		display: none; } }



//BREAKPOINS
@media all and (max-width: $mobile) {
	.login-modal {
		width: 320px;

		h3 {
			left: 10px; }

		.fa-times {
			right: 10px; }

		form {
			padding: 30px;

			.divide {
				width: calc(100% + 60px);
				margin: 20px 0 35px -30px; } } } }







