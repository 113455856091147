/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: 'OpenSans';
  src: url("/assets/fonts/OpenSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Semibold';
  src: url("/assets/fonts/OpenSans-Semibold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url("/assets/fonts/OpenSans-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto';
  src: url("/assets/fonts/NotoSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto';
  src: url("/assets/fonts/NotoSans-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'fa';
  src: url("/assets/fonts/fontawesome-webfont.woff");
  src: url("/assets/fonts/fontawesome-webfont.eot");
  src: url("/assets/fonts/fontawesome-webfont.ttf");
  font-weight: normal;
  font-style: normal;
}

p, span, h1, h2, h3, h4, h5, li, a, label, footer, div {
  font-family: "OpenSans", "Noto", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "Microsoft JhengHei", sans-serif;
  color: #5D5D5D;
  line-height: 1.3em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: keep-all;
}

h1 {
  font-size: 44px;
  line-height: 52px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
  line-height: 39px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

p, span, div {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.7em;
  word-break: keep-all;
}

a:active,
a:focus,
a:hover {
  text-decoration: none;
}

a:visited,
button:visited {
  color: #FFF;
}

.page-container .btn:active, .page-container .btn:focus, .page-container .btn:hover,
.page-container a:active,
.page-container a:focus,
.page-container a:hover {
  text-decoration: none;
  color: #FFF;
}

.main-content {
  position: relative;
  width: 1110px;
  background-color: #FFF;
  margin: -80px auto 100px;
}

.content-header {
  position: absolute;
  top: -40px;
  left: 2px;
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
}

.content-buttons {
  position: absolute;
  top: -20px;
  right: 40px;
  width: 810px;
  height: 70px;
}

.content-buttons .content-btn {
  display: inline-block;
  width: 165px;
  height: 45px;
  background-color: #A2BBB4;
  color: #FFF;
  text-align: center;
  padding: 12px;
  color: #FFF;
  border-radius: 3px;
  float: left;
  margin: 0 10px;
  transition: background-color .3s;
}

.content-buttons .content-btn:hover {
  background-color: #2A51AA;
}

.content-buttons .content-btn.content-btn-4 {
  width: 230px;
}

.content-buttons .active-btn {
  background-color: #4AD6AE !important;
}

.pagination-nav li {
  display: inline-block;
  margin: 0 15px;
}

.pagination-nav li a {
  color: #8A8A8A;
}

.pagination-nav li[rel="prev"] a,
.pagination-nav li[rel="next"] a {
  color: #4AD6AE;
  margin: 0 10px;
}

.pagination-nav li.inactive a {
  display: none;
}

.pagination-nav li.active-item a {
  color: #4AD6AE;
}

.pagination-nav-mobile {
  display: none;
  position: relative;
  width: 350px;
  margin: 0 auto;
  text-align: center;
}

.pagination-nav-mobile li {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: 0 5px;
  background-color: #009B9D;
  border: solid 2px #009B9D;
}

.pagination-nav-mobile li a {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 4px;
  color: #FFF;
  font-weight: bold;
}

.pagination-nav-mobile li[rel="prev"],
.pagination-nav-mobile li[rel="next"] {
  margin: 0 5px;
  background: transparent;
  border: transparent;
}

.pagination-nav-mobile li[rel="prev"] a span,
.pagination-nav-mobile li[rel="next"] a span {
  color: #009B9D;
}

.pagination-nav-mobile .active-item {
  background-color: #FFF;
}

.pagination-nav-mobile .active-item a {
  color: #009B9D;
}

.inline {
  display: inline-block;
  vertical-align: top;
}

.hidden {
  display: none !important;
}

.active {
  display: block !important;
}

.hero {
  width: 100%;
  height: 410px;
  position: relative;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.block-anchor {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.btn {
  width: 155px;
  height: 50px;
  background-color: #4AD6AE;
  color: #FFF;
  text-align: center;
  padding: 14px;
  border-radius: 3px;
}

.btn:hover {
  cursor: pointer;
}

.back-to-btn {
  display: none;
  position: relative;
  width: 200px;
  padding: 14px 50px;
  text-align: right;
}

.back-to-btn span {
  display: inline-block;
  position: absolute;
  top: 16px;
  left: 25px;
  font-size: 1.2em;
  color: #FFF;
}

@media all and (max-width: 1109px) {
  .main-content {
    width: 95%;
  }
  .content-buttons {
    display: none;
  }
}

@media all and (max-width: 720px) {
  .main-content {
    width: 100%;
  }
  .content-header {
    display: none;
  }
  .content-buttons {
    display: none;
  }
  .content-buttons .content-btn {
    width: 140px;
    height: 50px;
    padding: 14px 5px;
  }
  .content-buttons .content-btn-1 {
    margin-right: 10px;
  }
  .content-buttons .content-btn-2 {
    left: 150px;
  }
  .back-to-btn {
    display: static;
  }
  .pagination-nav {
    display: none;
  }
  .pagination-nav-mobile {
    display: block;
  }
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
  min-width: 320px;
  min-height: 100vh;
  height: 100%;
  background-color: #F3F1F0;
  margin: 0 auto !important;
}

.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 9999;
}

.loading-page.hidden {
  display: none;
}

.overlay-mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.overlay-mask.active {
  display: block;
}

nav.desktop-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1920px;
  height: 100px;
  margin: 0 auto;
  background: linear-gradient(rgba(5, 64, 64, 0.7), rgba(0, 128, 128, 0.5));
  z-index: 10;
}

nav.desktop-nav .desktop-nav-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 1110px;
  height: 100px;
  margin: 0 auto;
}

nav.desktop-nav .desktop-nav-wrapper .logo-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 110px;
  height: 100px;
  background-color: #FFF;
}

nav.desktop-nav .desktop-nav-wrapper .logo-container a {
  padding: 7px 4px 7px 5px;
}

nav.desktop-nav .desktop-nav-wrapper .logo-container a img {
  width: 99px;
  height: 86px;
}

nav.desktop-nav .desktop-nav-wrapper .company-full-name {
  position: absolute;
  top: 0;
  left: 120px;
  width: 275px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 8px;
  color: #FFF;
  font-weight: bold;
  line-height: 1.5em;
}

nav.desktop-nav .desktop-nav-wrapper .member-log-in {
  position: absolute;
  top: 0;
  right: 0;
  width: 170px;
  height: 40px;
  padding: 10px;
  font-size: 18px;
  line-height: 29px;
  font-family: "OpenSans-Semibold", "OpenSans", "Noto", "lato", "Helvetica", "Microsoft JhengHei", sans-serif;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  text-align: right;
  color: #FFF;
}

nav.desktop-nav .desktop-nav-wrapper .logout-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 40px;
  padding: 7px 0;
  font-size: 18px;
  line-height: 29px;
  font-family: "OpenSans-Semibold", "OpenSans", "Noto", "lato", "Helvetica", "Microsoft JhengHei", sans-serif;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  text-align: right;
}

nav.desktop-nav .desktop-nav-wrapper .logout-button a {
  margin-right: 30px;
  color: #FFF;
}

nav.desktop-nav .desktop-nav-wrapper .logout-button:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 14px;
  right: 4px;
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #FFF transparent;
}

nav.desktop-nav .desktop-nav-wrapper .logout-button a[href="/user/logout"] {
  display: block;
  width: 100%;
  height: 40px;
  padding: 10px;
}

nav.desktop-nav .desktop-nav-wrapper .logout-button:hover .login-sub-menu {
  opacity: 1;
  pointer-events: auto;
  transition: opacity .5s .1s;
}

nav.desktop-nav .desktop-nav-wrapper .login-sub-menu {
  opacity: 0;
  position: absolute;
  top: 40px;
  right: 0;
  width: 155px;
  height: 90px;
  padding: 0;
  border-radius: 3px;
  background-color: black;
  transition: opacity .5s .15s;
  pointer-events: none;
  text-align: center;
  z-index: 1;
}

nav.desktop-nav .desktop-nav-wrapper .login-sub-menu li {
  width: 100%;
  height: 45px;
  margin: 0;
  font-size: .9em;
}

nav.desktop-nav .desktop-nav-wrapper .login-sub-menu li:first-of-type {
  border-radius: 3px 3px 0 0;
}

nav.desktop-nav .desktop-nav-wrapper .login-sub-menu li:last-of-type {
  border-radius: 0 0 3px 3px;
  background-color: #2A51AA;
}

nav.desktop-nav .desktop-nav-wrapper .login-sub-menu li a {
  display: inline-block;
  width: 100%;
  height: 45px;
  color: #FFF;
  padding: 14px 0;
  font-family: "OpenSans-Semibold", "OpenSans", "Noto", "lato", "Helvetica", "Microsoft JhengHei", sans-serif;
}

nav.desktop-nav .desktop-nav-wrapper .navigation {
  position: absolute;
  display: inline-block;
  top: 62px;
  right: 0;
  margin: 0 10px;
  font-weight: bold;
}

nav.desktop-nav .desktop-nav-wrapper .navigation li {
  position: relative;
  display: inline-block;
  height: 24px;
  margin-left: 40px;
  color: #FFF;
}

nav.desktop-nav .desktop-nav-wrapper .navigation li a {
  display: inline-block;
  color: #FFF;
  height: 21px;
  font-family: "OpenSans-Semibold", "OpenSans", "Noto", "lato", "Helvetica", "Microsoft JhengHei", sans-serif;
  font-size: 18px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  transition: color .2s;
}

nav.desktop-nav .desktop-nav-wrapper .navigation li a:hover {
  color: #50e3c2;
}

nav.desktop-nav .desktop-nav-wrapper .navigation .dd {
  position: relative;
}

nav.desktop-nav .desktop-nav-wrapper .navigation .dd .sub-menu {
  opacity: 0;
  position: absolute;
  top: 30px;
  right: 0;
  width: 192px;
  padding: 0;
  transition: opacity .5s .15s;
  pointer-events: none;
  text-align: center;
}

nav.desktop-nav .desktop-nav-wrapper .navigation .dd .sub-menu li {
  width: 100%;
  height: 50px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color .2s;
}

nav.desktop-nav .desktop-nav-wrapper .navigation .dd .sub-menu li:first-of-type {
  border-radius: 5px 5px 0 0;
}

nav.desktop-nav .desktop-nav-wrapper .navigation .dd .sub-menu li:last-of-type {
  border-radius: 0 0 5px 5px;
}

nav.desktop-nav .desktop-nav-wrapper .navigation .dd .sub-menu li:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

nav.desktop-nav .desktop-nav-wrapper .navigation .dd .sub-menu li a {
  display: inline-block;
  width: 100%;
  height: 50px;
  padding: 15px;
  font-family: "OpenSans", "Noto", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "Microsoft JhengHei", sans-serif;
  font-size: 13px;
  font-weight: lighter !important;
}

nav.desktop-nav .desktop-nav-wrapper .navigation .dd .sub-menu-2 {
  height: 97px;
}

nav.desktop-nav .desktop-nav-wrapper .navigation .dd > a {
  width: 100%;
  height: 50px;
}

nav.desktop-nav .desktop-nav-wrapper .navigation .dd > a:hover {
  color: #FFF;
}

nav.desktop-nav .desktop-nav-wrapper .navigation .dd:hover > .sub-menu {
  opacity: 1;
  pointer-events: auto;
  transition: opacity .5s .1s;
}

nav.desktop-nav .desktop-nav-wrapper .navigation .dd-arrow-down {
  display: inline-block;
  top: 17px;
  width: 15px;
  height: 25px;
  margin: 0 auto;
}

.page-container {
  flex: 1 0 auto;
}

footer {
  width: 100%;
  height: 250px;
  background-color: #009B9D;
  z-index: 9;
}

footer .footer-wrapper {
  position: relative;
  width: 1110px;
  height: 240px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

footer .footer-wrapper img {
  position: absolute;
  top: -20px;
  left: 0;
}

footer .footer-wrapper .contact-info {
  position: absolute;
  top: 50px;
  left: 600px;
}

footer .footer-wrapper .contact-info div {
  display: inline-block;
  margin-right: 60px;
}

footer .footer-wrapper .contact-info div span, footer .footer-wrapper .contact-info div p, footer .footer-wrapper .contact-info div h5 {
  color: #FFF;
  font-size: 13px;
  line-height: 1.3em;
}

footer .footer-wrapper .contact-info div h5 {
  font-weight: bold;
  margin-bottom: 1em;
}

footer .footer-wrapper .fontech-logo {
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 30px;
  left: 0;
}

footer .footer-wrapper .fontech-logo span {
  color: white !important;
  font-size: 12px;
}

footer .footer-wrapper .fontech-logo img {
  position: relative;
  top: auto;
  left: auto;
  width: 156px;
  margin-left: 16px;
}

nav.mobile-nav {
  display: none;
}

nav.mobile-nav .header-strip {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 11;
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.2);
}

nav.mobile-nav .header-strip h1.page-name {
  margin: 5px 10px 0 0;
  font-size: 18px;
  line-height: 30px;
}

nav.mobile-nav .lines-button {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 12;
  display: inline-block;
  width: 50px;
  height: 50px;
  padding: 8px 10px 15px;
  transition: .3s;
  cursor: pointer;
  user-select: none;
  background-color: #2A51AA;
  appearance: none;
  border: none;
  opacity: 1;
}

nav.mobile-nav .lines-button:focus {
  outline: 0;
}

nav.mobile-nav .lines {
  display: inline-block;
  width: 30px;
  height: 3px;
  background: white;
  transition: 0.3s;
  border-radius: 3px;
  position: relative;
  /*create the upper and lower lines as pseudo-elements of the middle line*/
}

nav.mobile-nav .lines:before, nav.mobile-nav .lines:after {
  display: inline-block;
  width: 30px;
  height: 3px;
  background: white;
  transition: 0.3s;
  border-radius: 3px;
  position: absolute;
  left: 0;
  content: '';
  transform-origin: 2.1428571429px center;
}

nav.mobile-nav .lines:before {
  top: 8px;
}

nav.mobile-nav .lines:after {
  top: -8px;
}

nav.mobile-nav .lines-button.x.close .lines {
  /*hide the middle line*/
  background: transparent;
  /*overlay the lines by setting both their top values to 0*/
}

nav.mobile-nav .lines-button.x.close .lines:before, nav.mobile-nav .lines-button.x.close .lines:after {
  transform-origin: 50% 50%;
  top: 0;
  width: 30px;
}

nav.mobile-nav .lines-button.x.close .lines:before {
  transform: rotate3d(0, 0, 1, 45deg);
}

nav.mobile-nav .lines-button.x.close .lines:after {
  transform: rotate3d(0, 0, 1, -45deg);
}

nav.mobile-nav .hidden-mobile {
  top: -670px !important;
}

nav.mobile-nav .navigation-mobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 55px 0 30px;
  text-align: center;
  background-color: rgba(47, 91, 189, 0.95);
  box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

nav.mobile-nav .navigation-mobile li {
  display: inline-block;
  width: 100%;
}

nav.mobile-nav .navigation-mobile li a {
  display: inline-block;
  width: 100%;
  height: 50px;
  padding: 12px;
  color: white;
  font-size: 20px;
  transition: background-color .1s ease-in;
}

nav.mobile-nav .navigation-mobile li .log-in-btn {
  width: 260px;
  height: 40px;
  margin: 15px auto 0;
  padding: 8px;
  font-size: 16px;
  background-color: #009B9D;
}

nav.mobile-nav .navigation-mobile .mobile-dropdown-wrapper {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  transition: height .3s, opacity .3s;
}

nav.mobile-nav .navigation-mobile .mobile-dropdown-wrapper li {
  height: 50px;
}

nav.mobile-nav .navigation-mobile .mobile-dropdown-wrapper li a {
  font-size: 18px;
}

nav.mobile-nav .navigation-mobile .mobile-dropdown-wrapper.submenu-1 {
  height: 210px;
}

nav.mobile-nav .navigation-mobile .mobile-dropdown-wrapper.submenu-2 {
  height: 160px;
}

nav.mobile-nav .navigation-mobile .mobile-dropdown-wrapper.submenu-3 {
  height: 165px;
}

nav.mobile-nav .navigation-mobile .expand-menu {
  position: relative;
  height: 50px;
}

nav.mobile-nav .navigation-mobile .expand-menu:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 23px;
  right: calc(100%/2 - 50px);
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #FFF transparent;
}

nav.mobile-nav .navigation-mobile .expand-menu.expand-2 {
  margin-top: -17px;
}

nav.mobile-nav .navigation-mobile .expand-menu.expand-2:after {
  right: calc(100%/2 - 85px);
}

nav.mobile-nav .navigation-mobile .expand-menu.expand-3:after {
  right: calc(100%/2 - 70px);
}

nav.mobile-nav .navigation-mobile .menu-collapse {
  height: 0 !important;
  opacity: 0 !important;
}

nav.mobile-nav .navigation-mobile .negative-margin {
  margin-top: -17px;
}

nav.mobile-nav .fade-in {
  transition: top 0.7s cubic-bezier(0.26, 0.96, 0.52, 0.95);
}

nav.mobile-nav .fade-out {
  transition: top 1s cubic-bezier(0.69, 0, 0.28, 0.98);
}

@media all and (max-width: 1109px) {
  nav.desktop-nav {
    display: none;
  }
  nav.mobile-nav {
    display: block;
  }
  nav.mobile-nav .header-strip {
    height: 50px;
  }
  nav.mobile-nav .header-strip h1.page-name {
    font-size: 20px;
    line-height: 40px;
  }
  nav.mobile-nav .hamburger-menu {
    width: 50px;
    height: 50px;
    padding: 10px 13px;
    font-size: 28px;
  }
  nav.mobile-nav .fa-times {
    padding: 10px 13px;
    font-size: 28px;
  }
  nav.mobile-nav .navigation-mobile {
    padding: 65px 0 30px;
  }
  footer {
    height: 300px;
  }
  footer .footer-wrapper {
    position: relative;
    width: 100%;
    height: 300px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  footer .footer-wrapper img {
    position: absolute;
    top: -40px;
    left: 20px;
  }
  footer .footer-wrapper .contact-info {
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    width: 450px;
    margin-left: auto;
  }
  footer .footer-wrapper .contact-info div {
    margin: 0;
  }
  footer .footer-wrapper .contact-info div:first-of-type {
    margin-right: 50px;
  }
  footer .footer-wrapper .fontech-logo {
    left: 20px;
    bottom: 32px;
  }
  footer .footer-wrapper .fontech-logo img {
    width: 132px;
  }
}

@media all and (max-width: 720px) {
  nav.desktop-nav {
    display: none;
  }
  footer {
    width: 100%;
    height: 500px;
  }
  footer .footer-wrapper {
    position: relative;
    width: 100%;
    height: 500px;
  }
  footer .footer-wrapper img {
    top: -25px;
    left: 0;
    right: 0;
    width: 129px;
    height: 49px;
    margin: 0 auto;
  }
  footer .footer-wrapper .contact-info {
    top: 80px;
    left: 0;
    right: 0;
    width: 250px;
    margin: 0 auto;
    text-align: center;
  }
  footer .footer-wrapper .contact-info div:first-of-type {
    margin-right: 0;
  }
  footer .footer-wrapper .contact-info span, footer .footer-wrapper .contact-info p, footer .footer-wrapper .contact-info h5 {
    font-size: 1em;
  }
  footer .footer-wrapper .contact-info h5 {
    margin-bottom: 1.1em;
  }
  footer .footer-wrapper .contact-info > div {
    margin-bottom: 40px;
    margin-right: 0;
  }
  footer .footer-wrapper .fontech-logo {
    bottom: 24px;
    left: 50% !important;
    transform: translateX(-50%);
  }
  footer .footer-wrapper .fontech-logo img {
    width: 142px;
  }
}

.login-modal {
  display: none;
  position: absolute;
  top: 15vh;
  left: 0;
  right: 0;
  width: 520px;
  height: auto;
  margin: 0 auto;
  background-color: #FFF;
  z-index: 10000;
}

.login-modal.active {
  display: block;
}

.login-modal h3 {
  position: absolute;
  top: -50px;
  color: #FFF;
}

.login-modal .lines-button-login {
  z-index: 12;
  display: inline-block;
  width: 50px;
  height: 50px;
  padding: 8px 10px 15px;
  cursor: pointer;
  user-select: none;
  appearance: none;
  border: none;
  opacity: 1;
}

.login-modal .lines-button-login:focus {
  outline: 0;
}

.login-modal .lines {
  display: inline-block;
  width: 30px;
  height: 3px;
  background: white;
  border-radius: 3px;
  position: relative;
  /*create the upper and lower lines as pseudo-elements of the middle line*/
}

.login-modal .lines:before, .login-modal .lines:after {
  display: inline-block;
  width: 30px;
  height: 3px;
  background: white;
  border-radius: 3px;
  position: absolute;
  left: 0;
  content: '';
  transform-origin: 2.1428571429px center;
}

.login-modal .lines:before {
  top: 8px;
}

.login-modal .lines:after {
  top: -8px;
}

.login-modal .lines-button-login.x.close .lines {
  /*hide the middle line*/
  background: transparent;
  /*overlay the lines by setting both their top values to 0*/
}

.login-modal .lines-button-login.x.close .lines:before, .login-modal .lines-button-login.x.close .lines:after {
  transform-origin: 50% 50%;
  top: 0;
  width: 30px;
}

.login-modal .lines-button-login.x.close .lines:before {
  transform: rotate3d(0, 0, 1, 45deg);
}

.login-modal .lines-button-login.x.close .lines:after {
  transform: rotate3d(0, 0, 1, -45deg);
}

.login-modal .close-login-modal {
  position: absolute;
  top: -55px;
  right: -10px;
  cursor: pointer;
}

.login-modal img {
  display: block;
  width: 99px;
  height: 90px;
  margin: 40px auto 0;
}

.login-modal form {
  width: 100%;
  padding: 45px;
}

.login-modal form input {
  width: 100%;
  height: 50px;
  font-family: "OpenSans", "Noto", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "Microsoft JhengHei", sans-serif;
  color: black;
  border: none;
  border-radius: 3px;
  line-height: 1.5em;
  outline: 0;
  margin-bottom: 15px;
  padding: 10px;
  resize: none;
  font-size: .9rem;
  background-color: #eeeeee;
}

.login-modal form input::-webkit-input-placeholder {
  color: #8A8A8A;
  font-family: "OpenSans", "Noto", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "Microsoft JhengHei", sans-serif;
}

.login-modal form input:-moz-placeholder {
  color: #8A8A8A;
  font-family: "OpenSans", "Noto", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "Microsoft JhengHei", sans-serif;
}

.login-modal form input::-moz-placeholder {
  color: #8A8A8A;
  font-family: "OpenSans", "Noto", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "Microsoft JhengHei", sans-serif;
}

.login-modal form input:-ms-input-placeholder {
  color: #8A8A8A;
  font-family: "OpenSans", "Noto", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "Microsoft JhengHei", sans-serif;
}

.login-modal form input:focus {
  border: solid 1px #4AD6AE;
  box-shadow: 0 0 1px 1px rgba(74, 214, 174, 0.5);
}

.login-modal form button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 3px;
  font-size: 1.1em;
  background-color: #4AD6AE;
  color: #FFF;
  margin: 30px auto 15px;
}

.login-modal form .message {
  text-align: center;
}

.login-modal form .message a {
  display: inline-block;
  color: red;
  padding-left: 5px;
}

.login-modal form .divide {
  width: calc(100% + 90px);
  border-bottom: solid 3px #F3F1F0;
  margin: 20px 0 35px -45px;
}

.login-modal .register-form {
  display: none;
}

@media all and (max-width: 720px) {
  .login-modal {
    width: 320px;
  }
  .login-modal h3 {
    left: 10px;
  }
  .login-modal .fa-times {
    right: 10px;
  }
  .login-modal form {
    padding: 30px;
  }
  .login-modal form .divide {
    width: calc(100% + 60px);
    margin: 20px 0 35px -30px;
  }
}

.index-hero {
  position: relative;
  width: 100%;
  height: 700px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.index-hero .hero-toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.index-hero .index-hero-1 {
  background-image: url("/assets/img/index-hero-2-m.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  animation-name: picture-toggle;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  animation-delay: 11s;
}

.index-hero .index-hero-2 {
  background-image: url("/assets/img/hero01.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  animation-name: picture-toggle;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  animation-delay: 7s;
}

.index-hero .index-hero-3 {
  background-image: url("/assets/img/hero02.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  animation-name: picture-toggle;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  animation-delay: 3s;
}

.index-hero .index-hero-4 {
  background-image: url("/assets/img/hero03.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  animation-name: picture-toggle-first;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  animation-delay: 0s;
}

.index-hero .index-hero-5 {
  background-image: url("/assets/img/hero20170526glbexbd.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  animation-name: picture-toggle-first;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 16s;
  animation-delay: 14s;
}

@keyframes picture-toggle-first {
  0% {
    opacity: 1;
  }
  18.75% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  93.75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes picture-toggle {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  31.25% {
    opacity: 0;
  }
  93.75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slogan {
  position: absolute;
  top: 225px;
  left: 0;
  right: 0;
  width: 1100px;
  margin: 0 auto;
}

.slogan h1 {
  margin-bottom: 30px;
}

.slogan h1, .slogan h2 {
  color: #FFF;
  text-shadow: 2px 4px 6px rgba(0, 0, 0, 0.7);
  font-weight: bold;
}

.slogan .btn {
  position: absolute;
  top: 140px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 14px 10px;
}

.welcome-wrapper {
  position: relative;
  width: 1110px;
  height: 430px;
  margin: -150px auto 100px;
  padding: 40px 30px 40px 50px;
  background-color: #FFF;
  z-index: 2;
}

.welcome-wrapper .welcome {
  width: 458px;
  float: left;
}

.welcome-wrapper .welcome h3 {
  margin-bottom: 1em;
  color: #2A51AA;
  font-weight: bold;
}

.welcome-wrapper .welcome p {
  font-size: 18px;
  line-height: 26px;
}

.welcome-wrapper .upcoming-events {
  width: 485px;
  float: left;
}

.welcome-wrapper .upcoming-events h3 {
  margin-bottom: 1.2em;
  color: #23b49f;
  font-weight: bold;
}

.welcome-wrapper .upcoming-events .event-row {
  margin-bottom: 25px;
}

.welcome-wrapper .upcoming-events .event-row h5 {
  font-weight: bold;
}

.welcome-wrapper .upcoming-events .event-row div {
  display: inline-block;
  float: left;
}

.welcome-wrapper .upcoming-events .event-row .event-date {
  width: 30%;
}

.welcome-wrapper .upcoming-events .event-row .event-date .date {
  display: block;
  font-size: .8em;
  color: #8A8A8A;
}

.welcome-wrapper .upcoming-events .event-row .event-info {
  width: 70%;
}

.welcome-wrapper .upcoming-events .event-row .event-info p {
  font-size: .9em;
  color: #8A8A8A;
}

.welcome-wrapper .vertical-line {
  width: 2px;
  height: 300px;
  float: left;
  margin: 10px 35px 0 47px;
  background-color: #F3F1F0;
}

.welcome-wrapper .see-more {
  display: inline-block;
  position: absolute;
  right: 56px;
  bottom: 20px;
  color: #4AD6AE;
}

.latest-news-wrapper {
  position: relative;
  width: 1110px;
  margin: 120px auto 80px;
  padding: 20px 0 20px;
}

.latest-news-wrapper .news-list {
  float: left;
  width: 46%;
}

.latest-news-wrapper .news-list h3 {
  position: absolute;
  top: -50px;
  margin-bottom: 2em;
  color: #2A51AA;
  font-weight: bold;
}

.latest-news-wrapper .news-list .news-item {
  width: 100%;
  margin-bottom: 40px;
}

.latest-news-wrapper .news-list .news-item h5 {
  color: #009B9D;
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: bold;
}

.latest-news-wrapper .news-list .news-item .date {
  display: block;
  width: 100%;
  font-size: 14px;
  color: #8A8A8A;
  border-bottom: solid 1px #dee8e8;
  margin-bottom: 7px;
}

.latest-news-wrapper .news-list .news-item .description {
  font-size: 13px;
  color: black;
}

.latest-news-wrapper .company-video {
  position: relative;
  float: right;
  width: 504px;
  height: 320px;
  margin-left: 30px;
}

.latest-news-wrapper .company-video iframe {
  width: 504px;
  height: 284px;
}

.latest-news-wrapper .btn {
  position: absolute;
  right: 130px;
  bottom: -30px;
  width: 220px;
  height: 45px;
  font-family: "OpenSans-Semibold", "OpenSans", "Noto", "lato", "Helvetica", "Microsoft JhengHei", sans-serif;
  font-size: 15px;
  padding: 12px;
  font-weight: bold;
  transition: background-color .3s;
}

.latest-news-wrapper .btn:hover {
  background-color: #2A51AA;
}

.index-events-photo-wrap {
  position: relative;
  width: 1124px;
  min-height: 100px;
  height: auto;
  overflow: visible;
  margin: 90px auto 60px;
}

.index-events-photo-wrap .album-wrap {
  width: 1124px;
  min-height: 360px;
}

.index-events-photo-wrap .album-wrap .single-event {
  width: 360px;
  height: 360px;
  float: left;
  overflow: visible;
  margin: 0px 7px;
}

.index-events-photo-wrap .album-wrap .single-event .img-wrap {
  width: 348px;
  height: 195px;
  position: relative;
  overflow: visible;
}

.index-events-photo-wrap .album-wrap .single-event .img-wrap:after {
  content: "";
  width: 348px;
  height: 195px;
  background-color: #2a51aa;
  top: 12px;
  left: 12px;
  position: absolute;
  display: block;
  z-index: 0;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}

.index-events-photo-wrap .album-wrap .single-event .img-wrap img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 348px;
  height: 195px;
  display: block;
  z-index: 1;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}

.index-events-photo-wrap .album-wrap .single-event .img-wrap:hover:after {
  top: 0px;
  left: 0px;
}

.index-events-photo-wrap .album-wrap .single-event .img-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 348px;
  height: 195px;
  display: block;
  z-index: 1;
}

.index-events-photo-wrap .album-wrap .single-event .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  color: #009b9d;
  margin-top: 35px;
}

.index-events-photo-wrap .album-wrap .single-event .time {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.19;
  color: #2951aa;
  margin-top: 14px;
}

.index-events-photo-wrap .btn {
  width: 240px;
  height: 45px;
  font-family: "OpenSans-Semibold", "OpenSans", "Noto", "lato", "Helvetica", "Microsoft JhengHei", sans-serif;
  font-size: 15px;
  padding: 12px;
  font-weight: bold;
  transition: background-color .3s;
}

.index-events-photo-wrap .btn:hover {
  background-color: #2A51AA;
}

.partner-wrapper-outer {
  width: 100%;
  min-height: 630px;
  background-color: #FFF;
  margin-bottom: 100px;
  padding: 35px 0;
  overflow: hidden;
}

.partner-wrapper-outer .partner-title {
  font-family: OpenSans;
  font-weight: bold;
  color: #2a51aa;
  font-size: 24px;
  width: 100%;
  margin-left: 6px;
}

.partner-wrapper-outer .partner-wrapper-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1110px;
  margin: 0 auto;
}

.partner-wrapper-outer .partner-wrapper-inner div {
  position: relative;
  background-color: #FFF;
  margin: 10px auto;
  overflow: hidden;
}

.partner-wrapper-outer .partner-wrapper-inner div a img {
  position: absolute;
  height: auto;
  width: 100%;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
}

.partner-wrapper-outer .partner-wrapper-inner div.small {
  width: 210px;
  height: 145px;
}

.partner-wrapper-outer .partner-wrapper-inner div.big {
  width: 360px;
  height: 145px;
}

@media all and (max-width: 1109px) {
  .index-hero {
    height: 375px;
  }
  .index-hero .hide-for-mobile {
    display: none;
  }
  .index-hero-mobile {
    background-image: url("/assets/img/hero01.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 0%;
    height: 375px;
    z-index: 1;
  }
  .slogan {
    width: 95%;
    top: 155px;
    padding: 0 20px;
    margin: 0 auto;
    text-align: center;
  }
  .slogan h1 {
    font-size: 36px;
    margin-bottom: 0;
  }
  .slogan h2 {
    font-size: 18px;
  }
  .welcome-wrapper {
    width: 95%;
    height: auto;
    margin: -70px auto 20px;
    padding: 35px 60px 40px;
  }
  .welcome-wrapper .welcome {
    width: 100%;
    float: none;
    margin-bottom: 60px;
  }
  .welcome-wrapper .welcome h3 {
    margin-bottom: 1em;
  }
  .welcome-wrapper .upcoming-events {
    width: 100%;
    float: none;
  }
  .welcome-wrapper .upcoming-events h3 {
    margin: 1.1em 0;
  }
  .welcome-wrapper .see-more {
    right: 56px;
    bottom: 10px;
  }
  .welcome-wrapper .vertical-line {
    display: none;
  }
  .latest-news-wrapper {
    width: 95%;
    height: auto;
    margin: 80px auto;
    padding: 20px 60px;
  }
  .latest-news-wrapper .news-list {
    float: none;
    width: 600px;
    padding: 0;
  }
  .latest-news-wrapper .company-video {
    float: none;
    width: 560px;
    hight: 315px;
    margin: 50px 0;
  }
  .latest-news-wrapper .company-video iframe {
    width: 560px;
    height: 315px;
  }
  .latest-news-wrapper .company-video .btn {
    bottom: -60px;
  }
  .index-events-photo-wrap {
    width: 95%;
    height: auto;
    margin: 80px auto;
    padding: 20px 60px;
  }
  .index-events-photo-wrap .album-wrap {
    width: 100%;
    min-height: 435px;
  }
  .partner-wrapper-outer {
    width: 100%;
    height: auto;
    margin-bottom: 100px;
    padding: 25px 15px;
  }
  .partner-wrapper-outer .partner-wrapper-inner {
    width: 95%;
    margin: 0 auto;
  }
}

@media all and (max-width: 720px) {
  .index-hero-mobile,
  .index-hero {
    height: 200px;
  }
  .slogan {
    top: 80px;
  }
  .slogan h1 {
    font-size: 21px;
    line-height: 23px;
    margin-bottom: 10px;
  }
  .slogan h2 {
    font-size: 16px;
  }
  .welcome-wrapper {
    width: 100%;
    margin: 0 auto 50px;
    padding: 40px 20px;
  }
  .welcome-wrapper .welcome {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
  .welcome-wrapper .welcome h3 {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 1em;
  }
  .welcome-wrapper .welcome p {
    font-size: 14px;
    line-height: 21px;
  }
  .welcome-wrapper .welcome .btn {
    margin-bottom: 22px;
  }
  .welcome-wrapper .upcoming-events {
    width: 100%;
    float: none;
  }
  .welcome-wrapper .upcoming-events h3 {
    font-size: 20px;
    line-height: 23px;
    margin: 1.1em 0;
  }
  .welcome-wrapper .upcoming-events .event-row .event-date {
    width: 100%;
    margin-bottom: 5px;
  }
  .welcome-wrapper .upcoming-events .event-row .event-date .date {
    display: inline;
  }
  .welcome-wrapper .upcoming-events .event-row .event-info {
    width: 100%;
  }
  .welcome-wrapper .see-more {
    right: 20px;
  }
  .latest-news-wrapper {
    margin: 80px auto;
    padding: 0;
  }
  .latest-news-wrapper .news-list {
    float: none;
    width: 100%;
    padding: 0 20px;
  }
  .latest-news-wrapper .news-list h3 {
    font-size: 20px;
    line-height: 23px;
  }
  .latest-news-wrapper .company-video {
    float: none;
    width: 90%;
    max-width: 500px;
    height: 250px;
    margin: 40px auto;
  }
  .latest-news-wrapper .company-video iframe {
    width: 100%;
    height: 250px;
  }
  .latest-news-wrapper .company-video .btn {
    left: 0;
    margin: 0 auto;
  }
  .index-events-photo-wrap {
    width: 95%;
    margin: 0 auto;
    padding: 0;
  }
  .index-events-photo-wrap .album-wrap {
    width: 100%;
  }
  .index-events-photo-wrap .album-wrap .single-event {
    float: initial;
    width: 100%;
    max-width: 500px;
    height: 250px;
    margin: 100px auto;
  }
  .index-events-photo-wrap .album-wrap .single-event .img-wrap {
    width: 100%;
    height: 240px;
    position: relative;
    overflow: visible;
  }
  .index-events-photo-wrap .album-wrap .single-event .img-wrap:after {
    content: "";
    width: 100%;
    height: 240px;
    background-color: #2a51aa;
    top: 10px;
    left: 10px;
    position: absolute;
    display: block;
    z-index: 0;
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition: 0.2s linear;
  }
  .index-events-photo-wrap .album-wrap .single-event .img-wrap img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 240px;
    display: block;
    z-index: 1;
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition: 0.2s linear;
  }
  .index-events-photo-wrap .album-wrap .single-event .img-wrap:hover img {
    top: -10px;
    left: -10px;
  }
  .index-events-photo-wrap .album-wrap .single-event .img-wrap:hover:after {
    top: 0px;
    left: 0px;
  }
  .index-events-photo-wrap .album-wrap .single-event .img-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 240px;
    display: block;
    z-index: 1;
  }
  .partner-wrapper-outer {
    width: 100%;
    height: auto;
    background-color: #FFF;
    margin-bottom: 100px;
    padding: 25px 20px;
  }
  .partner-wrapper-outer .partner-wrapper-inner {
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
  .partner-wrapper-outer .partner-wrapper-inner h4 {
    margin-bottom: 40px;
  }
  .partner-wrapper-outer .partner-wrapper-inner a {
    position: static;
    display: block;
    width: 100%;
    margin: 0 auto 30px;
    text-align: center;
  }
}

.about-us-hero {
  background-image: url("/assets/img/about-us-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}

.about-content-wrapper {
  width: 100%;
  padding: 120px 180px;
}

.about-content-wrapper p {
  font-size: 16px;
}

.about-content-wrapper .about-header {
  color: #2A51AA;
  margin-bottom: 25px;
}

.about-content-wrapper p.history {
  color: #009B9D;
  margin-bottom: 69px;
}

.about-content-wrapper .president-photo {
  float: left;
  width: 160px;
  margin-bottom: 20px;
}

.about-content-wrapper .president-photo p {
  font-weight: bold;
}

.about-content-wrapper .president-photo img {
  max-width: 160px;
}

.about-content-wrapper p.president-message {
  float: right;
  width: calc(100% - 200px);
  margin-bottom: 50px;
}

.about-content-wrapper img.president-signature {
  float: none;
  margin-left: 200px;
  margin-bottom: 75px;
}

.about-content-wrapper p.vision {
  margin-bottom: 60px;
}

.about-content-wrapper ul.objectives {
  margin-bottom: 50px;
}

.about-content-wrapper ul.objectives li {
  position: relative;
  line-height: 1.8em;
  padding-left: 20px;
}

.about-content-wrapper ul.objectives li:before {
  position: absolute;
  left: -1px;
  content: "\2022";
  font-size: 1.2em;
}

.about-content-wrapper .company-logo {
  float: left;
  margin: 30px 0 0 15px;
}

.about-content-wrapper .logo-description {
  float: left;
  width: 560px;
  margin-left: 40px;
}

@media all and (max-width: 1109px) {
  .about-content-wrapper {
    width: 100%;
    padding: 60px;
  }
  .about-content-wrapper p.history {
    margin-bottom: 40px;
  }
  .about-content-wrapper p.vision {
    margin-bottom: 50px;
  }
  .about-content-wrapper .logo-description {
    width: 65%;
  }
}

@media all and (max-width: 720px) {
  .about-us-hero {
    height: 200px;
  }
  .about-content-wrapper {
    padding: 60px 20px 20px;
  }
  .about-content-wrapper .about-header {
    margin-bottom: 25px;
    font-size: 21px;
    line-height: 23px;
  }
  .about-content-wrapper .president-photo {
    float: none;
  }
  .about-content-wrapper p.president-message {
    float: none;
    width: 100%;
  }
  .about-content-wrapper img.president-signature {
    margin-left: 0px;
  }
  .about-content-wrapper p.vision {
    margin-bottom: 30px;
  }
  .about-content-wrapper ul.objectives {
    margin-bottom: 30px;
  }
  .about-content-wrapper img.company-logo {
    display: none;
  }
  .about-content-wrapper .logo-description {
    display: none;
  }
}

.board-members-hero {
  background-image: url("/assets/img/board-member-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}

.board-members-content-wrapper {
  padding: 34px 95px;
}

.board-members-content-wrapper .row {
  border-bottom: solid 1px #8A8A8A;
}

.board-members-content-wrapper .row .member {
  float: left;
  width: 450px;
}

.board-members-content-wrapper .row .member img {
  width: 108px;
  height: 108px;
  float: left;
  margin: 40px 20px 40px 0;
  border-radius: 50%;
  background-color: #e6e5e5;
}

.board-members-content-wrapper .row .member .p-4 {
  padding: 4px;
}

.board-members-content-wrapper .row .member-description {
  width: 300px;
  height: 180px;
  float: left;
  padding-top: 55px;
}

.board-members-content-wrapper .row .member-description span {
  display: inline-block;
  font-size: 14px;
  color: #8A8A8A;
  vertical-align: top;
}

.board-members-content-wrapper .row .member-description span.name {
  font-weight: bold;
  font-size: 18px;
  color: #141413;
  line-height: 26px;
}

.board-members-content-wrapper .row .member-description span.title {
  color: #2A51AA;
  margin-bottom: 6px;
}

.board-members-content-wrapper .row .member-description span p {
  line-height: 1.2em;
  font-size: 14px;
  color: #8A8A8A;
}

.board-members-content-wrapper .row-1 .member {
  width: 600px;
}

.board-members-content-wrapper .row-6,
.board-members-content-wrapper .advisory-council {
  border-bottom: none;
}

@media all and (max-width: 1109px) {
  .board-members-content-wrapper {
    padding: 30px;
  }
  .board-members-content-wrapper .row {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
  }
  .board-members-content-wrapper .row .member {
    width: 50%;
  }
  .board-members-content-wrapper .row .member-description {
    width: 180px;
    height: 180px;
  }
}

@media all and (max-width: 720px) {
  .board-members-hero {
    height: 200px;
  }
  .board-members-content-wrapper {
    padding: 34px 15px;
  }
  .board-members-content-wrapper .content-header {
    top: -35px;
  }
  .board-members-content-wrapper .row {
    max-width: 500px;
    margin-bottom: 20px;
  }
  .board-members-content-wrapper .row .member {
    float: none;
    margin: 0 auto;
    width: 100%;
  }
  .board-members-content-wrapper .row .member img {
    width: 75px;
    height: 75px;
    margin: 10px 15px 40px 0;
  }
  .board-members-content-wrapper .row .member-description {
    width: calc(100% - 90px);
    height: 130px;
    padding-top: 10px;
    overflow: hidden;
  }
  .board-members-content-wrapper .row .member-description .name {
    font-weight: bold;
    color: black;
  }
}

.membership-hero {
  background-image: url("/assets/img/membership-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}

.membership-content-wrapper {
  width: 100%;
  padding: 120px 180px;
}

.membership-content-wrapper section {
  margin-bottom: 50px;
}

.membership-content-wrapper section h3 {
  color: #2A51AA;
  margin-bottom: 25px;
}

.membership-content-wrapper section h4 {
  color: #009B9D;
  margin-bottom: 15px;
}

.membership-content-wrapper section p {
  margin-bottom: 20px;
  font-size: .9em;
}

.membership-content-wrapper section ul li {
  position: relative;
  line-height: 1.8em;
  font-size: 13px;
  padding-left: 15px;
  margin-bottom: 15px;
}

.membership-content-wrapper section ul li:before {
  position: absolute;
  left: -1px;
  color: #009B9D;
  content: "\2022";
  font-size: 1.7em;
}

.membership-content-wrapper section table {
  border: solid 2px #4AD6AE;
  margin: 0 auto;
}

.membership-content-wrapper section table th {
  text-align: center;
  background-color: #4AD6AE;
  color: #FFF;
  padding: 5px;
}

.membership-content-wrapper section table td {
  border-left: solid 2px #4AD6AE;
  padding: 7px 50px 5px 30px;
}

.membership-content-wrapper section table tr:nth-of-type(2) td {
  padding-top: 10px;
}

.membership-content-wrapper section table tr:last-of-type td {
  padding-bottom: 10px;
}

.membership-content-wrapper .become-member {
  display: inline-block;
  position: absolute;
  bottom: -25px;
  right: 100px;
  width: 300px;
  height: 55px;
  padding: 16px 20px;
  border-radius: 3px;
  background-color: #2A51AA;
  text-align: left;
}

.membership-content-wrapper .become-member .fa-long-arrow-right {
  position: absolute;
  top: 14px;
  right: 20px;
  color: #FFF;
  font-size: 1.7em;
}

@media all and (max-width: 1109px) {
  .membership-content-wrapper {
    padding: 80px 70px;
  }
  .membership-content-wrapper section table td {
    padding: 7px 10px 5px 10px;
  }
  .membership-content-wrapper section table tr:nth-of-type(2) td {
    padding-top: 10px;
  }
  .membership-content-wrapper section table tr:last-of-type td {
    padding-bottom: 10px;
  }
  .membership-content-wrapper .become-member {
    bottom: 20px;
  }
}

@media all and (max-width: 720px) {
  .membership-hero {
    height: 200px;
  }
  .content-header {
    top: -35px;
  }
  .membership-content-wrapper {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 40px 20px 60px;
  }
  .membership-content-wrapper section h3 {
    font-size: 22px;
    line-height: 25px;
    font-weight: bold;
  }
  .membership-content-wrapper section h4 {
    font-size: 15px;
    line-height: 18px;
    font-weight: bold;
  }
  .membership-content-wrapper section table {
    border: solid 1px #4AD6AE;
  }
  .membership-content-wrapper section table th {
    text-align: center;
    background-color: #4AD6AE;
    color: #FFF;
    padding: 5px;
  }
  .membership-content-wrapper section table td {
    border-left: solid 1px #4AD6AE;
    padding: 7px 10px 5px 10px;
    vertical-align: top;
    line-height: 1.2em;
  }
  .membership-content-wrapper section table tr:nth-of-type(2) td {
    padding-top: 10px;
  }
  .membership-content-wrapper section table tr:last-of-type td {
    padding-bottom: 10px;
  }
  .membership-content-wrapper .become-member {
    bottom: 15px;
    right: 0;
    left: 0;
    width: 280px;
    margin: 0 auto;
    padding-left: 10px;
  }
  .membership-content-wrapper .become-member .fa-long-arrow-right {
    right: 15px;
  }
}

.member-directory-hero {
  background-image: url("/assets/img/membership-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}

.main-content.no-bg-color {
  background-color: #F3F1F0 !important;
}

.main-content .search-box {
  float: left;
  width: 315px;
  height: 670px;
  background-color: #4AD6AE;
  padding: 55px 38px;
}

.main-content .search-box h4 {
  color: #FFF;
  margin-bottom: 30px;
}

.main-content .search-box .dropdown-wrapper {
  position: relative;
  width: 240px;
  height: 44px;
  margin-bottom: 20px;
}

.main-content .search-box .dropdown-wrapper:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 15px;
  top: 25px;
  margin-top: -6px;
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #777777 transparent;
}

.main-content .search-box .dropdown {
  width: 240px;
  height: 44px;
  padding: 4px 9px;
  border: solid 1px #ced1d2;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  color: #777777;
}

.main-content .search-box .search-btn {
  width: 165px;
  padding: 9px;
  background: #2A51AA;
  color: #FFF;
  border: none;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  margin-top: 50px;
}

.main-content .search-box .content-search {
  width: 240px;
  height: 44px;
  padding: 12px;
  font-size: .9em;
}

.main-content .member-directory-content-wrapper {
  float: left;
  width: 795px;
  height: 1550px;
  background-color: #FFF;
  margin-bottom: 20px;
}

.main-content .member-directory-content-wrapper .content-buttons {
  width: 480px;
  display: block;
}

.main-content .member-directory-content-wrapper .content-buttons .content-btn {
  width: 220px;
  transition: background-color .2s;
}

.main-content .member-directory-content-wrapper .member-display {
  width: 100%;
  margin: 100px auto 50px;
  padding: 10px 60px;
}

.main-content .member-directory-content-wrapper .member-display .member-box {
  width: 100%;
  height: 230px;
  margin-bottom: 30px;
  border-bottom: solid 2px #F3F1F0;
}

.main-content .member-directory-content-wrapper .member-display .member-box .img-wrapper {
  position: relative;
  width: 35%;
  height: 220px;
  text-align: center;
  float: left;
}

.main-content .member-directory-content-wrapper .member-display .member-box .img-wrapper img {
  display: inline-block;
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  max-height: 80px;
  margin: 0 auto;
  width: 85%;
}

.main-content .member-directory-content-wrapper .member-display .member-box .info-wrapper {
  position: relative;
  float: left;
  width: 65%;
  height: 230px;
}

.main-content .member-directory-content-wrapper .member-display .member-box h3 {
  color: #2A51AA;
  font-weight: bold;
  font-size: 24px;
}

.main-content .member-directory-content-wrapper .member-display .member-box .full-name {
  display: block;
  color: black;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: .7em;
}

.main-content .member-directory-content-wrapper .member-display .member-box .info p, .main-content .member-directory-content-wrapper .member-display .member-box .info span {
  color: black;
  font-size: .8rem;
}

.main-content .member-directory-content-wrapper .member-display .member-box .info a {
  color: #009B9D;
}

.main-content .member-directory-content-wrapper .member-display .member-box .view-detail {
  position: absolute;
  right: 0;
  bottom: 15px;
  width: 120px;
  height: 35px;
  padding: 7px;
  background-color: #2A51AA;
  font-size: 13px;
}

.main-content .pagination-nav {
  text-align: center;
}

@media all and (max-width: 1109px) {
  .main-content .search-box {
    position: absolute;
    top: -200px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 580px;
    height: 100px;
    padding: 0;
    background-color: transparent;
  }
  .main-content .search-box h4 {
    margin-bottom: 5px;
  }
  .main-content .search-box .search-input {
    position: absolute;
    width: 580px;
  }
  .main-content .search-box .content-search,
  .main-content .search-box .dropdown {
    top: 0;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
    border: none;
  }
  .main-content .search-box .content-search::-webkit-input-placeholder,
  .main-content .search-box .dropdown::-webkit-input-placeholder {
    color: #5D5D5D;
    font-size: .9em;
  }
  .main-content .search-box .content-search:-moz-placeholder,
  .main-content .search-box .dropdown:-moz-placeholder {
    color: #5D5D5D;
    opacity: 1;
    font-size: .9em;
  }
  .main-content .search-box .content-search::-moz-placeholder,
  .main-content .search-box .dropdown::-moz-placeholder {
    color: #5D5D5D;
    opacity: 1;
    font-size: .9em;
  }
  .main-content .search-box .content-search:-ms-input-placeholder,
  .main-content .search-box .dropdown:-ms-input-placeholder {
    color: #5D5D5D;
    font-size: .9em;
  }
  .main-content .search-box .content-search {
    left: 0;
    width: 210px;
    height: 44px;
    padding: 12px;
  }
  .main-content .search-box .dropdown-wrapper {
    top: 0;
    left: 230px;
    width: 200px;
  }
  .main-content .search-box .dropdown-wrapper .dropdown {
    width: 200px;
    padding: 4px 12px;
    font-size: 13px;
  }
  .main-content .search-box .search-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 120px;
    height: 44px;
    display: block;
    background-color: #4AD6AE;
    margin-top: 0;
    padding: 10px;
    border-radius: 2px;
  }
  .main-content .member-directory-content-wrapper {
    float: none;
    width: 100%;
  }
  .main-content .member-directory-content-wrapper .content-buttons {
    left: 50%;
    margin-left: -239px;
  }
}

@media all and (max-width: 720px) {
  .member-directory-hero {
    height: 350px;
  }
  .main-content {
    margin: 0 auto 50px;
  }
  .main-content .search-box {
    top: -220px;
    width: 220px;
    height: 200px;
  }
  .main-content .search-box h4 {
    display: none;
  }
  .main-content .search-box .dropdown-wrapper {
    top: 60px;
    left: 0;
    width: 220px;
  }
  .main-content .search-box .dropdown-wrapper .dropdown {
    width: 220px;
  }
  .main-content .search-box .content-search {
    top: 0;
    left: 0;
    width: 220px;
  }
  .main-content .search-box .search-btn {
    top: 130px;
    left: 0;
    width: 220px;
  }
  .main-content .member-directory-content-wrapper {
    width: 100%;
    height: 2200px;
    margin-bottom: 0;
    margin-left: 0;
  }
  .main-content .member-directory-content-wrapper .content-buttons {
    top: 160px;
    left: 0;
    width: 309px;
    display: none;
  }
  .main-content .member-directory-content-wrapper .content-buttons .content-btn {
    position: absolute;
    width: 152px;
    height: 40px;
    padding: 10px 2px;
    background-color: #A2BBB4;
    transition: background-color .2s;
    font-size: .9em;
  }
  .main-content .member-directory-content-wrapper .content-buttons .content-btn-1 {
    top: 0;
    left: 0;
  }
  .main-content .member-directory-content-wrapper .content-buttons .content-btn-2 {
    top: 0;
    left: 157px;
  }
  .main-content .member-directory-content-wrapper .content-buttons .active {
    background-color: #4AD6AE;
  }
  .main-content .member-directory-content-wrapper .member-display {
    width: 100%;
    margin: 0 auto 10px;
    padding: 30px 20px 10px;
  }
  .main-content .member-directory-content-wrapper .member-display .member-box {
    position: relative;
    width: 100%;
    min-height: 380px;
  }
  .main-content .member-directory-content-wrapper .member-display .member-box .img-wrapper {
    float: none;
    width: 100%;
    height: 190px;
    margin: 0 auto;
  }
  .main-content .member-directory-content-wrapper .member-display .member-box .img-wrapper img {
    top: 5%;
    width: 85%;
  }
  .main-content .member-directory-content-wrapper .member-display .member-box .info-wrapper {
    float: none;
    width: 300px;
    height: 250px;
    margin: 0 auto;
  }
  .main-content .member-directory-content-wrapper .member-display .member-box .info-wrapper .full-name {
    line-height: 1.3em;
  }
  .main-content .member-directory-content-wrapper .member-display .member-box .info-wrapper .view-detail {
    position: relative;
    bottom: 0;
    margin: 10px auto 20px auto;
  }
}

.association-member-detail-hero {
  background-image: url("/assets/img/membership-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}

.asc-detail-content-wrapper {
  width: 100%;
  padding: 20px 165px 100px;
}

.asc-detail-content-wrapper section {
  margin: 50px 0;
}

.asc-detail-content-wrapper section h3 {
  color: #2A51AA;
  margin-bottom: 35px;
}

.asc-detail-content-wrapper section h4 {
  color: #009B9D;
  margin-bottom: 25px;
}

.asc-detail-content-wrapper section p {
  margin-bottom: 40px;
  font-size: .9em;
}

.asc-detail-content-wrapper section .info {
  position: relative;
}

.asc-detail-content-wrapper section .info .details {
  width: 70%;
}

.asc-detail-content-wrapper section .info .details p {
  font-size: .9em;
  line-height: 1.4em;
}

.asc-detail-content-wrapper section .info .details p span {
  line-height: 1.4em;
  font-size: .9em;
}

.asc-detail-content-wrapper section .info .details p img {
  position: absolute;
  top: 0;
  right: 0;
}

.asc-detail-content-wrapper section .info .history p {
  line-height: 1.4em;
}

.asc-detail-content-wrapper .pictures-mobile {
  display: none;
}

.asc-detail-content-wrapper .pictures-desktop {
  padding: 0;
}

.asc-detail-content-wrapper .pictures-desktop .pictures {
  text-align: center;
}

.asc-detail-content-wrapper .pictures-desktop .pictures img:nth-of-type(2) {
  margin: 0 10px;
}

.asc-detail-content-wrapper .history p {
  color: #2A51AA;
}

.asc-detail-content-wrapper .back-to-btn {
  display: none;
}

@media all and (max-width: 1109px) {
  .asc-detail-content-wrapper {
    width: 100%;
    padding: 20px 50px 100px;
  }
}

@media all and (max-width: 720px) {
  .association-member-detail-hero {
    height: 200px;
  }
  .asc-detail-content-wrapper {
    width: 100%;
    padding: 20px 20px 10px;
  }
  .asc-detail-content-wrapper section {
    margin: 30px 0;
    padding: 0 10px;
  }
  .asc-detail-content-wrapper section:first-of-type {
    margin-top: 100px;
  }
  .asc-detail-content-wrapper section:last-of-type {
    margin-bottom: 60px;
  }
  .asc-detail-content-wrapper section h3 {
    color: #2A51AA;
    margin-bottom: 35px;
    font-size: 22px;
    line-height: 25px;
    font-weight: bold;
  }
  .asc-detail-content-wrapper section h4 {
    color: #009B9D;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
  }
  .asc-detail-content-wrapper section p {
    margin-bottom: 40px;
    font-size: .9em;
  }
  .asc-detail-content-wrapper section .info .details p {
    font-size: .9em;
    line-height: 1.4em;
  }
  .asc-detail-content-wrapper section .info .details p span {
    line-height: 1.4em;
    font-size: .9em;
  }
  .asc-detail-content-wrapper section .info .details p img {
    position: absolute;
    top: -220px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .asc-detail-content-wrapper section .info .history p {
    line-height: 1.4em;
  }
  .asc-detail-content-wrapper .pictures-desktop {
    display: none;
  }
  .asc-detail-content-wrapper .pictures-mobile {
    display: block;
    margin: 0 0 0 -20px;
    padding: 0;
  }
  .asc-detail-content-wrapper .pictures-mobile .carousel {
    width: 100vw;
    height: 200px;
    overflow: hidden;
  }
  .asc-detail-content-wrapper .back-to-btn {
    display: block;
    margin: 0 auto;
    background-color: #2A51AA;
  }
  .asc-detail-content-wrapper .history p {
    color: #2A51AA;
  }
}

.news-room-hero {
  background-image: url("/assets/img/membership-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}

.news-room-content {
  height: 210px;
  overflow-y: hidden;
}

.news-room-content-wrapper {
  position: relative;
  width: 1110px;
  margin: -80px auto 100px;
}

.news-room-content-wrapper .content-header {
  position: absolute;
  top: -40px;
  left: 2px;
}

.news-room-content-wrapper .item-wrapper {
  width: 100%;
}

.news-room-content-wrapper .item-wrapper > a {
  float: left;
}

.news-room-content-wrapper .item-wrapper > a:nth-of-type(3n+2) {
  margin-left: 30px;
  margin-right: 30px;
}

.news-room-content-wrapper .item-wrapper > a {
  margin-bottom: 40px;
  border-bottom: solid 6px #4AD6AE;
}

.news-room-content-wrapper .item-wrapper > a:hover {
  border-bottom: solid 6px #2A51AA;
}

.news-room-content-wrapper .item-wrapper > a > div {
  position: relative;
  width: 350px;
  height: 400px;
  padding: 30px 35px;
  background-color: #FFF;
}

.news-room-content-wrapper .item-wrapper > a > div h6 {
  display: inline-block;
  width: 110%;
  margin-left: -15px;
  margin-bottom: 20px;
  padding: 5px 10px 10px 15px;
  border-bottom: solid 1px #F3F1F0;
  color: #009B9D;
}

.news-room-content-wrapper .item-wrapper > a > div p {
  color: black;
  font-size: .85em;
  line-height: 1.4em;
}

.news-room-content-wrapper .item-wrapper > a > div .date {
  position: absolute;
  display: inline-block;
  right: 35px;
  bottom: 20px;
  color: #8A8A8A;
  font-size: .9em;
}

.news-room-content-wrapper .item-wrapper > a > div .fa-file-pdf-o {
  display: inline-block;
  margin-bottom: 20px;
  color: #4AD6AE;
  font-size: 3em;
}

.news-room-content-wrapper .pagination-nav {
  text-align: center;
}

@media all and (max-width: 1109px) {
  .news-room-hero {
    height: 320px;
  }
  .news-room-content-wrapper {
    width: 100%;
  }
  .news-room-content-wrapper .content-header {
    left: 12%;
  }
  .news-room-content-wrapper .item-wrapper {
    width: 730px;
    margin: 0 auto;
  }
  .news-room-content-wrapper .item-wrapper a:nth-of-type(3n+2) {
    margin-left: 0;
    margin-right: 0;
  }
  .news-room-content-wrapper .item-wrapper a:nth-of-type(2n+2) {
    margin-left: 30px;
  }
}

@media all and (max-width: 720px) {
  .news-room-content-wrapper {
    width: 100%;
  }
  .news-room-content-wrapper .item-wrapper {
    width: 320px;
  }
  .news-room-content-wrapper .item-wrapper a:nth-of-type(2n+2) {
    margin-left: 0;
  }
  .news-room-content-wrapper .item-wrapper > a > div {
    max-width: 320px;
    height: 430px;
    margin: 0 0 40px;
  }
  .news-room-content-wrapper .item-wrapper > a {
    margin: 0;
  }
}

.afeca-events-hero {
  background-image: url("/assets/img/membership-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}

.main-content.afeca .afeca-events-content-wrapper {
  float: left;
  width: 100%;
  background-color: #FFF;
  margin-bottom: 100px;
}

.main-content.afeca .afeca-events-content-wrapper .content-buttons {
  top: -22px;
  right: 70px;
  width: 525px;
}

.main-content.afeca .afeca-events-content-wrapper .content-buttons .content-btn {
  position: absolute;
  width: 165px;
  height: 45px;
  padding: 13px 2px;
  font-size: .9em;
  margin: 0;
}

.main-content.afeca .afeca-events-content-wrapper .content-buttons .content-btn-1 {
  top: 0;
  left: 0;
}

.main-content.afeca .afeca-events-content-wrapper .content-buttons .content-btn-2 {
  top: 0;
  left: 185px;
}

.main-content.afeca .afeca-events-content-wrapper .content-buttons .content-btn-3 {
  top: 0;
  left: 370px;
}

.main-content.afeca .afeca-events-content-wrapper .content-buttons .active {
  background-color: #4AD6AE;
}

.main-content.afeca .afeca-events-content-wrapper .content-btn-2 {
  position: absolute;
  width: 215px;
  height: 70px;
  top: -35px;
  right: 100px;
  padding: 22px;
}

.main-content.afeca .afeca-events-content-wrapper .member-display {
  width: 730px;
  margin: 120px auto 50px;
  padding: 10px 0;
}

.main-content.afeca .afeca-events-content-wrapper .member-display .member-box {
  width: 730px;
  height: 420px;
  margin-bottom: 40px;
  border-bottom: solid 6px #2951aa;
}

.main-content.afeca .afeca-events-content-wrapper .member-display .member-box .img-wrapper {
  position: relative;
  width: 332px;
  height: 414px;
  float: left;
  overflow: hidden;
}

.main-content.afeca .afeca-events-content-wrapper .member-display .member-box .img-wrapper img {
  position: absolute;
  height: 100%;
  width: auto;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
}

.main-content.afeca .afeca-events-content-wrapper .member-display .member-box .info-wrapper {
  position: relative;
  float: left;
  width: 398px;
  height: 414px;
  padding: 50px 30px 0 50px;
}

.main-content.afeca .afeca-events-content-wrapper .member-display .member-box .info-wrapper .date {
  display: inline-block;
  width: 100%;
  padding: 5px 10px 10px 0;
  border-bottom: solid 1px #F3F1F0;
  color: #2A51AA;
}

.main-content.afeca .afeca-events-content-wrapper .member-display .member-box .info-wrapper .address {
  margin: 15px 0 25px 0;
}

.main-content.afeca .afeca-events-content-wrapper .member-display .member-box .info-wrapper .address .fa-map-marker {
  color: #4AD6AE;
  font-size: 2em;
  vertical-align: top;
}

.main-content.afeca .afeca-events-content-wrapper .member-display .member-box .info-wrapper .address p {
  display: inline-block;
  width: 250px;
  padding-left: 15px;
  font-size: .8em;
  color: #2A51AA;
  line-height: 1.4em;
}

.main-content.afeca .afeca-events-content-wrapper .member-display .member-box .info-wrapper .info {
  font-size: .9em;
  line-height: 1.4em;
}

.main-content.afeca .afeca-events-content-wrapper .member-display .member-box h4 {
  color: #009B9D;
}

.main-content.afeca .afeca-events-content-wrapper .member-display .member-box .full-name {
  display: block;
  color: black;
  font-size: 1.2em;
  margin-bottom: .7em;
}

.main-content.afeca .afeca-events-content-wrapper .member-display .member-box .info p, .main-content.afeca .afeca-events-content-wrapper .member-display .member-box .info span {
  color: black;
  font-size: .8rem;
}

.main-content.afeca .afeca-events-content-wrapper .member-display .member-box .info a {
  color: #009B9D;
}

.main-content.afeca .afeca-events-content-wrapper .member-display .member-box .view-detail {
  position: absolute;
  right: 0;
  bottom: 30px;
  width: 140px;
  height: 40px;
  padding: 10px;
  background-color: #009B9D;
}

.main-content.afeca .pagination-nav {
  text-align: center;
}

@media all and (max-width: 1109px) {
  .main-content.afeca {
    min-width: 720px;
  }
  .main-content.afeca .afeca-events-content-wrapper .content-buttons {
    right: 0;
  }
  .main-content.afeca .afeca-events-content-wrapper .member-display {
    width: 720px;
    margin: 80px auto 50px;
  }
  .main-content.afeca .afeca-events-content-wrapper .member-display .member-box {
    width: 700px;
    margin: 0 auto 40px;
  }
  .main-content.afeca .afeca-events-content-wrapper .member-display .member-box .img-wrapper {
    width: 330px;
  }
  .main-content.afeca .afeca-events-content-wrapper .member-display .member-box .img-wrapper img {
    height: auto;
    width: 100%;
  }
  .main-content.afeca .afeca-events-content-wrapper .member-display .member-box .info-wrapper {
    width: 370px;
    padding: 50px 15px 0 20px;
  }
}

@media all and (max-width: 720px) {
  .main-content.afeca {
    min-width: 320px;
  }
  .afeca-events-hero {
    height: 300px;
  }
  .main-content.afeca {
    background-color: #F3F1F0;
  }
  .main-content.afeca .afeca-events-content-wrapper {
    width: 100%;
    height: auto;
    background-color: #F3F1F0;
  }
  .main-content.afeca .afeca-events-content-wrapper .content-btn-2 {
    top: -35px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .main-content.afeca .afeca-events-content-wrapper .member-display {
    width: 100%;
    max-width: 300px;
    margin: 30px auto 50px;
  }
  .main-content.afeca .afeca-events-content-wrapper .member-display .member-box {
    width: 100%;
    height: auto;
    background-color: #FFF;
  }
  .main-content.afeca .afeca-events-content-wrapper .member-display .member-box .img-wrapper {
    width: 100%;
    height: 330px;
  }
  .main-content.afeca .afeca-events-content-wrapper .member-display .member-box .img-wrapper img {
    height: auto;
    width: 100%;
  }
  .main-content.afeca .afeca-events-content-wrapper .member-display .member-box .info-wrapper {
    width: 100%;
    height: auto;
    padding: 20px;
  }
  .main-content.afeca .afeca-events-content-wrapper .member-display .member-box .info-wrapper .date {
    padding: 5px 10px 0 15px;
    border-bottom: solid 2px #F3F1F0;
  }
  .main-content.afeca .afeca-events-content-wrapper .member-display .member-box .info-wrapper .address p {
    width: 230px;
  }
  .main-content.afeca .afeca-events-content-wrapper .member-display .member-box .info-wrapper .info {
    margin-bottom: 80px;
  }
  .main-content.afeca .afeca-events-content-wrapper .member-display .member-box .view-detail {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

.contact-us-hero {
  background-image: url("/assets/img/membership-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}

.contact-us-content-wrapper {
  padding: 50px 190px;
}

.contact-us-content-wrapper .contact-form-container {
  width: 730px;
  margin: 50px auto 100px;
}

.contact-us-content-wrapper .contact-form-container .flexbox-container {
  display: flex;
  justify-content: space-between;
}

.contact-us-content-wrapper .contact-form-container .flexbox-container .flex-item-1 {
  width: 255px;
}

.contact-us-content-wrapper .contact-form-container .flexbox-container .flex-item-2 {
  width: 445px;
}

.contact-us-content-wrapper .contact-form-container .flexbox-container .flex-item-2 .btn {
  float: left;
}

.contact-us-content-wrapper .contact-form-container .flexbox-container .flex-item-2 .g-recaptcha {
  float: right;
}

.contact-us-content-wrapper .contact-form-container label {
  position: absolute;
}

.contact-us-content-wrapper .contact-form-container legend {
  color: #2A51AA;
  font-size: 2em;
  margin-bottom: 40px;
}

.contact-us-content-wrapper .contact-form-container input,
.contact-us-content-wrapper .contact-form-container textarea {
  width: 100%;
  height: 36px;
  font-family: "OpenSans", "Noto", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "Microsoft JhengHei", sans-serif;
  color: black;
  border: solid 1px #2A51AA;
  border-radius: 3px;
  line-height: 1.5em;
  outline: 0;
  margin-bottom: 10px;
  padding: 10px;
  resize: none;
  font-size: .9rem;
}

.contact-us-content-wrapper .contact-form-container input::-webkit-input-placeholder,
.contact-us-content-wrapper .contact-form-container textarea::-webkit-input-placeholder {
  color: #8A8A8A;
  font-family: "OpenSans", "Noto", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "Microsoft JhengHei", sans-serif;
}

.contact-us-content-wrapper .contact-form-container input:-moz-placeholder,
.contact-us-content-wrapper .contact-form-container textarea:-moz-placeholder {
  color: #8A8A8A;
  font-family: "OpenSans", "Noto", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "Microsoft JhengHei", sans-serif;
}

.contact-us-content-wrapper .contact-form-container input::-moz-placeholder,
.contact-us-content-wrapper .contact-form-container textarea::-moz-placeholder {
  color: #8A8A8A;
  font-family: "OpenSans", "Noto", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "Microsoft JhengHei", sans-serif;
}

.contact-us-content-wrapper .contact-form-container input:-ms-input-placeholder,
.contact-us-content-wrapper .contact-form-container textarea:-ms-input-placeholder {
  color: #8A8A8A;
  font-family: "OpenSans", "Noto", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "Microsoft JhengHei", sans-serif;
}

.contact-us-content-wrapper .contact-form-container input:focus,
.contact-us-content-wrapper .contact-form-container textarea:focus {
  border: solid 1px #4AD6AE;
  box-shadow: 0 0 1px 1px rgba(74, 214, 174, 0.5);
}

.contact-us-content-wrapper .contact-form-container textarea {
  height: 175px;
}

.contact-us-content-wrapper .contact-form-container button {
  width: 125px;
  padding: 5px;
  height: 35px;
  border: none;
  border-radius: 3px;
  font-size: 1.1em;
  background-color: #2A51AA;
}

.contact-us-content-wrapper .address-container {
  width: 730px;
  margin-bottom: 40px;
}

.contact-us-content-wrapper .address-container .contact-info {
  float: left;
  width: 255px;
}

.contact-us-content-wrapper .address-container .contact-info span, .contact-us-content-wrapper .address-container .contact-info p {
  font-size: .95em;
  line-height: 1.3em;
}

.contact-us-content-wrapper .address-container .contact-info h5 {
  color: #4AD6AE;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
}

.contact-us-content-wrapper .address-container .google-map-container {
  float: right;
  width: 445px;
}

.contact-us-content-wrapper .address-container .google-map-container iframe {
  width: 445px;
  height: 230px;
}

@media all and (max-width: 1109px) {
  .contact-us-content-wrapper {
    padding: 20px;
  }
  .contact-us-content-wrapper .contact-form-container {
    width: 80%;
  }
  .contact-us-content-wrapper .contact-form-container .flexbox-container {
    justify-content: flex-start;
  }
  .contact-us-content-wrapper .contact-form-container input {
    width: 95%;
  }
  .contact-us-content-wrapper .contact-form-container textarea {
    width: 100%;
  }
  .contact-us-content-wrapper .contact-form-container .align {
    text-align: right;
  }
  .contact-us-content-wrapper .contact-form-container .align button {
    width: 100px;
    height: 35px;
    padding: 5px;
    font-size: .9em;
  }
  .contact-us-content-wrapper .address-container {
    margin: 0 auto 40px;
  }
  .contact-us-content-wrapper .address-container .contact-info {
    width: 250px;
  }
}

@media all and (max-width: 720px) {
  .contact-us-hero {
    height: 200px;
  }
  .contact-us-content-wrapper {
    padding: 50px 20px;
  }
  .contact-us-content-wrapper .contact-form-container {
    width: 100%;
    max-width: 320px;
    margin: 0 auto 50px;
  }
  .contact-us-content-wrapper .contact-form-container legend {
    display: none;
  }
  .contact-us-content-wrapper .contact-form-container .flexbox-container {
    flex-direction: column;
    justify-content: center;
  }
  .contact-us-content-wrapper .contact-form-container .flexbox-container input {
    width: 100%;
  }
  .contact-us-content-wrapper .contact-form-container .flexbox-container .flex-item-1 {
    width: 100%;
  }
  .contact-us-content-wrapper .contact-form-container .flexbox-container .flex-item-2 {
    width: 100%;
  }
  .contact-us-content-wrapper .address-container {
    width: 280px;
    margin: 0 auto 40px;
  }
  .contact-us-content-wrapper .address-container .contact-info {
    float: none;
    width: 280px;
    margin: 0 0 30px 0;
  }
  .contact-us-content-wrapper .address-container .google-map-container {
    float: none;
    width: 280px;
  }
  .contact-us-content-wrapper .address-container .google-map-container iframe {
    width: 280px;
    height: 230px;
  }
}

.news-detail-hero {
  background-image: url("/assets/img/membership-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  height: 500px;
}

.slick-prev:before, .slick-next:before {
  color: #4ad6ae !important;
}

.news-detail-content {
  margin-top: 0;
  margin-bottom: 250px;
}

.news-detail-content .news-header-box {
  position: absolute;
  top: -60px;
  width: 545px;
  height: auto;
  background-color: #4AD6AE;
  padding: 15px 30px;
}

.news-detail-content .news-header-box h3 {
  color: #FFF;
  font-size: 25px;
  font-weight: normal;
  font-family: PingFangTC OpenSans;
  line-height: normal;
}

.news-detail-content .news-header-box p {
  color: #FFF;
  font-size: 14px;
  margin-top: 10px;
  font-family: OpenSans;
}

.news-detail-content .content-header {
  top: -100px;
}

.news-detail-content .news-detail-content-wrapper {
  width: 100%;
  padding: 200px 190px 100px;
}

.news-detail-content .news-detail-content-wrapper .news-gallery {
  margin: 0 auto 50px;
}

.news-detail-content .back-to-btn {
  display: block;
  position: absolute;
  width: 240px;
  margin: -40px auto 0;
  background-color: #2A51AA;
  padding: 14px 30px;
  margin: -25px 0 0 80px;
}

.news-detail-content .back-to-btn span {
  left: 15px;
}

@media all and (max-width: 1109px) {
  .news-detail-hero {
    height: 320px;
  }
  .news-detail-content .news-detail-content-wrapper {
    padding: 130px 15% 100px;
  }
  .news-detail-content .back-to-news {
    right: 70px;
  }
}

@media all and (max-width: 720px) {
  .news-detail-hero {
    height: 300px;
  }
  .news-detail-content {
    padding-bottom: 40px;
    margin-bottom: 100px;
  }
  .news-detail-content .news-header-box {
    top: -110px;
    left: 0;
    right: 0;
    width: 95%;
    height: 200px;
    margin: 0 auto;
  }
  .news-detail-content .news-detail-content-wrapper {
    width: 100%;
    padding: 120px 20px 100px;
  }
  .news-detail-content .back-to-news {
    display: none;
  }
  .news-detail-content .back-to-btn {
    position: relative;
    margin: -40px auto 0;
  }
}

.edit-member-hero {
  background-image: url("/assets/img/hero03.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-position: 50% 80%;
}

.edit-member-content-wrapper {
  width: 80%;
  margin: 0 auto;
  padding: 80px 0;
}

.edit-member-content-wrapper h3 {
  color: #2A51AA;
  border-bottom: solid 2px #eeeeee;
  padding-bottom: 10px;
  margin-bottom: 65px;
}

.edit-member-content-wrapper .form-wrapper {
  width: 80%;
  margin: 0 auto;
}

.edit-member-content-wrapper .form-wrapper .event-type-green {
  display: inline-block;
  float: left;
  font-size: 18px;
  color: #4AD6AE;
}

.edit-member-content-wrapper .form-wrapper .event-type-black {
  display: inline-block;
  float: right;
  font-size: 18px;
  color: black;
}

.edit-member-content-wrapper .form-wrapper form {
  margin-top: 50px;
}

.edit-member-content-wrapper .form-wrapper .photo-upload-header {
  margin-bottom: 40px;
}

.edit-member-content-wrapper .form-wrapper .photo-upload-header legend {
  font-size: 18px;
  color: #4AD6AE;
  margin-bottom: 8px;
}

.edit-member-content-wrapper .form-wrapper .form-line {
  text-align: right;
  margin-bottom: 25px;
}

.edit-member-content-wrapper .form-wrapper .form-line label {
  display: inline-block;
  vertical-align: top;
  padding-top: 7px;
  margin-right: 10px;
}

.edit-member-content-wrapper .form-wrapper .form-line input,
.edit-member-content-wrapper .form-wrapper .form-line textarea {
  width: 70%;
  height: 36px;
  font-family: "OpenSans", "Noto", "lato", "Helvetica", "AvenirNext-Regular", "Roboto", "HelveticaNeue-Light", "Helvetica", "Arial", "Heiti TC", "Microsoft JhengHei", sans-serif;
  color: black;
  border: solid 1px #a5a5a5;
  border-radius: 3px;
  line-height: 1.5em;
  outline: 0;
  padding: 10px;
  resize: none;
  font-size: .9rem;
}

.edit-member-content-wrapper .form-wrapper .form-line input:focus,
.edit-member-content-wrapper .form-wrapper .form-line textarea:focus {
  border: solid 1px #4AD6AE;
  box-shadow: 0 0 1px 1px rgba(74, 214, 174, 0.5);
}

.edit-member-content-wrapper .form-wrapper .form-line textarea {
  height: 200px;
}

.edit-member-content-wrapper .form-wrapper .upload-photo {
  position: relative;
  float: left;
  width: 220px;
  height: 120px;
  background-color: #eeeeee;
  margin: 0 20px 70px 0;
  text-align: center;
  cursor: pointer;
}

.edit-member-content-wrapper .form-wrapper .upload-photo:nth-of-type(2) {
  clear: left;
}

.edit-member-content-wrapper .form-wrapper .upload-photo:last-of-type {
  margin: 0 0 70px 0;
}

.edit-member-content-wrapper .form-wrapper .upload-photo p {
  position: absolute;
  top: -25px;
}

.edit-member-content-wrapper .form-wrapper .upload-photo .icon-add-pic {
  width: 64px;
  height: 57px;
  margin-top: 32px;
}

.edit-member-content-wrapper .form-wrapper .upload-photo input[type="file"] {
  display: none;
}

.edit-member-content-wrapper .form-wrapper .upload-photo img {
  width: 100%;
  height: auto;
}

.edit-member-content-wrapper .form-wrapper button {
  display: block;
  width: 175px;
  height: 45px;
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1.1em;
  color: white;
  background-color: #4AD6AE;
  margin: 80px auto;
}

@media all and (max-width: 1109px) {
  .edit-member-hero {
    height: 270px;
  }
  .edit-member-content-wrapper {
    width: 85%;
    max-width: 700px;
  }
  .edit-member-content-wrapper .form-wrapper {
    width: 100%;
  }
  .edit-member-content-wrapper .form-wrapper .form-line {
    margin-bottom: 15px;
  }
  .edit-member-content-wrapper .form-wrapper .form-line label {
    float: left;
  }
  .edit-member-content-wrapper .form-wrapper .form-line input,
  .edit-member-content-wrapper .form-wrapper .form-line textarea {
    width: 65%;
  }
  .edit-member-content-wrapper .form-wrapper .upload-photo {
    width: 184px;
    height: 108px;
    margin-right: calc((100% - (184px * 3)) / 2);
  }
  .edit-member-content-wrapper .form-wrapper .upload-photo:last-of-type {
    margin: 0 0 70px 0;
  }
  .edit-member-content-wrapper .form-wrapper button {
    width: 125px;
  }
}

@media all and (max-width: 720px) {
  .edit-member-hero {
    height: 200px;
  }
  .edit-member-content-wrapper {
    width: 90%;
    padding: 20px 0 80px;
  }
  .edit-member-content-wrapper .form-wrapper {
    width: 100%;
    max-width: 400px;
  }
  .edit-member-content-wrapper .form-wrapper form {
    margin-top: 35px;
  }
  .edit-member-content-wrapper .form-wrapper .event-type-green {
    font-size: 14px;
  }
  .edit-member-content-wrapper .form-wrapper .event-type-black {
    font-size: 14px;
  }
  .edit-member-content-wrapper .form-wrapper legend {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .edit-member-content-wrapper .form-wrapper .form-line {
    margin-bottom: 15px;
  }
  .edit-member-content-wrapper .form-wrapper .form-line label {
    margin-bottom: 4px;
    font-size: 14px;
  }
  .edit-member-content-wrapper .form-wrapper .form-line input,
  .edit-member-content-wrapper .form-wrapper .form-line textarea {
    width: 100%;
  }
  .edit-member-content-wrapper .form-wrapper .member-detail-photo {
    width: 184px;
    margin: 30px auto 0;
  }
  .edit-member-content-wrapper .form-wrapper .upload-photo {
    clear: both;
  }
}

.events-photo-wrap {
  width: 1000px;
  min-height: 100px;
  height: auto;
  overflow: visible;
  margin: 90px auto 177px;
}

.events-photo-wrap .single-event {
  width: 300px;
  height: 360px;
  float: left;
  overflow: visible;
  margin: 0px 15px;
}

.events-photo-wrap .single-event .img-wrap {
  width: 290px;
  height: 163px;
  position: relative;
  overflow: visible;
}

.events-photo-wrap .single-event .img-wrap:after {
  content: "";
  width: 290px;
  height: 163px;
  background-color: #2a51aa;
  top: 10px;
  left: 10px;
  position: absolute;
  display: block;
  z-index: 0;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}

.events-photo-wrap .single-event .img-wrap img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 290px;
  height: 163px;
  display: block;
  z-index: 1;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}

.events-photo-wrap .single-event .img-wrap:hover:after {
  top: 0px;
  left: 0px;
}

.events-photo-wrap .single-event .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  color: #009b9d;
  margin-top: 35px;
}

.events-photo-wrap .single-event .time {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.19;
  color: #2951aa;
  margin-top: 14px;
}

@media all and (max-width: 1050px) {
  .events-photo-wrap {
    width: 670px;
  }
}

@media all and (max-width: 655px) {
  .events-photo-wrap {
    width: 95%;
  }
  .events-photo-wrap .single-event {
    float: initial;
    margin: 0px auto;
  }
}

.gallery-detail-wrap {
  width: 765px;
  margin: 90px auto 100px;
  min-height: 100px;
  overflow: hidden;
}

.gallery-detail-wrap h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: #009b9d;
}

.gallery-detail-wrap .time {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #2951aa;
  margin-top: 24px;
  position: relative;
}

.gallery-detail-wrap .time:after {
  content: "";
  width: 765px;
  height: 1px;
  display: block;
  position: absolute;
  background-color: #dfe9e9;
  top: 30px;
  left: -2px;
}

.gallery-detail-wrap .location {
  font-size: 14px;
  line-height: 1.36;
  text-align: left;
  color: #2951aa;
  margin-top: 29px;
  position: relative;
  margin: 29px 0px 0px 25px;
}

.gallery-detail-wrap .location .fa {
  color: #4ad6ae;
  font-size: 24px;
  position: absolute;
  top: -4px;
  left: -25px;
}

.gallery-detail-wrap .intro {
  font-size: 14px;
  line-height: 1.57;
  color: #777777;
  margin-top: 28px;
  word-break: keep-all;
}

.gallery-detail-wrap .photos-wrap {
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  margin: 60px auto 0px;
}

.gallery-detail-wrap .photos-wrap .single-photo-wrap {
  width: 240px;
  height: 240px;
  margin: 0px 20px 20px 0px;
  display: block;
  float: left;
}

.gallery-detail-wrap .photos-wrap .single-photo-wrap:nth-child(3n) {
  margin: 0px 0px 20px 0px;
}

.gallery-detail-wrap .photos-wrap .single-photo {
  width: 240px;
  height: 240px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.gallery-detail-wrap .photos-wrap .single-photo:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 240px;
  height: 240px;
  opacity: 0;
  display: block;
  mix-blend-mode: multiply;
  color: white;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(140, 140, 140, 0.6) 1%, rgba(0, 0, 0, 0.6));
}

.gallery-detail-wrap .photos-wrap .single-photo:after {
  content: "View Fullsize";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 240px;
  height: 240px;
  line-height: 240px;
  text-align: center;
  font-weight: 600;
  color: white;
  opacity: 0;
  -webkit-transition: 0.2s linear;
  -moz-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}

.gallery-detail-wrap .photos-wrap .single-photo:hover:before {
  opacity: 1;
}

.gallery-detail-wrap .photos-wrap .single-photo:hover:after {
  opacity: 1;
}

.gallery-detail-wrap .photos-wrap .single-photo img {
  max-width: 500px;
  height: auto;
  min-width: 240px;
  min-height: 240px;
  align: middle;
}

@media all and (max-width: 850px) {
  .gallery-detail-wrap {
    width: 595px;
  }
  .gallery-detail-wrap .time:after {
    width: 605px;
  }
  .gallery-detail-wrap .photos-wrap .single-photo-wrap {
    width: 186px;
    height: 186px;
    margin: 0px 15px 15px 0px;
    display: block;
    float: left;
  }
  .gallery-detail-wrap .photos-wrap .single-photo-wrap:nth-child(3n) {
    margin: 0px 0px 15px 0px;
  }
  .gallery-detail-wrap .photos-wrap .single-photo {
    max-width: 186px;
    height: 186px;
  }
  .gallery-detail-wrap .photos-wrap .single-photo:before {
    width: 186px;
    height: 186px;
  }
  .gallery-detail-wrap .photos-wrap .single-photo:after {
    width: 186px;
    height: 186px;
    line-height: 186px;
  }
  .gallery-detail-wrap .photos-wrap img {
    max-width: 350px;
    height: autos;
    min-width: 186px;
    min-height: 186px;
  }
}

@media all and (max-width: 600px) {
  .gallery-detail-wrap {
    width: 282px;
  }
  .gallery-detail-wrap .time:after {
    width: 306px;
  }
  .gallery-detail-wrap .location {
    margin: 30px 0px 0px 30px;
  }
  .gallery-detail-wrap .location .fa {
    top: 3px;
    font-size: 30px;
    left: -30px;
  }
  .gallery-detail-wrap .photos-wrap .single-photo-wrap {
    width: 133px;
    height: 133px;
    margin: 0px 14px 14px 0px;
    display: block;
    overflow: hidden;
    float: left;
  }
  .gallery-detail-wrap .photos-wrap .single-photo-wrap:nth-child(3n) {
    margin: 0px 14px 14px 0px;
  }
  .gallery-detail-wrap .photos-wrap .single-photo-wrap:nth-child(2n) {
    margin: 0px 0px 14px 0px !important;
  }
  .gallery-detail-wrap .photos-wrap .single-photo {
    width: 133px;
    height: 133px;
  }
  .gallery-detail-wrap .photos-wrap .single-photo:before {
    width: 133px;
    height: 133px;
  }
  .gallery-detail-wrap .photos-wrap .single-photo:after {
    width: 133px;
    height: 133px;
    line-height: 133px;
  }
  .gallery-detail-wrap .photos-wrap img {
    max-width: 260px;
    height: auto;
    min-width: 133px;
    min-height: 133px;
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.dot {
  background: black;
  border-radius: 100%;
  color: white;
  height: 8px;
  line-height: 8px;
  text-align: center;
  width: 8px;
}

.dotOne {
  -webkit-animation: dotOneKeyframes 5s ease  infinite;
  background: #2951AA;
  position: absolute;
  top: 0;
  left: 0;
}

.dotTwo {
  -webkit-animation: dotTwoKeyframes 5s ease 0.4166666666666s infinite;
  background: #2951AA;
  position: absolute;
  top: 0;
  left: 14px;
}

.dotThree {
  -webkit-animation: dotThreeKeyframes 5s ease 0.83333333333s infinite;
  background: #4AD6AE;
  position: absolute;
  top: 14px;
  left: 14px;
}

@-webkit-keyframes dotOneKeyframes {
  0% {
    top: 0;
    left: 0;
  }
  8.3333333333% {
    top: 14px;
    left: 0;
  }
  25% {
    top: 14px;
    left: 0;
  }
  33.3333333333% {
    top: 14px;
    left: 14px;
  }
  50% {
    top: 14px;
    left: 14px;
  }
  58.3333333333% {
    top: 0;
    left: 14px;
  }
  75% {
    top: 0;
    left: 14px;
  }
  83.3333333333% {
    top: 0;
    left: 0;
  }
}

@-webkit-keyframes dotTwoKeyframes {
  0% {
    top: 0;
    left: 14px;
  }
  8.3333333333% {
    top: 0;
    left: 0;
  }
  25% {
    top: 0;
    left: 0;
  }
  33.3333333333% {
    top: 14px;
    left: 0;
  }
  50% {
    top: 14px;
    left: 0;
  }
  58.3333333333% {
    top: 14px;
    left: 14px;
  }
  75% {
    top: 14px;
    left: 14px;
  }
  83.3333333333% {
    top: 0;
    left: 14px;
  }
}

@-webkit-keyframes dotThreeKeyframes {
  0% {
    top: 14px;
    left: 14px;
  }
  8.3333333333% {
    top: 0;
    left: 14px;
  }
  25% {
    top: 0;
    left: 14px;
  }
  33.3333333333% {
    top: 0;
    left: 0;
  }
  50% {
    top: 0;
    left: 0;
  }
  58.3333333333% {
    top: 14px;
    left: 0;
  }
  75% {
    top: 14px;
    left: 0;
  }
  83.3333333333% {
    top: 14px;
    left: 14px;
  }
}

.fec-hero {
  background-image: url("/assets/img/membership-hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 0%;
}

.main-content.no-bg-color {
  background-color: #F3F1F0 !important;
}

.main-content .search-box {
  float: left;
  width: 315px;
  height: 670px;
  background-color: #4AD6AE;
  padding: 65px 38px;
}

.main-content .search-box h4 {
  color: #FFF;
  margin-bottom: 30px;
}

.main-content .search-box .dropdown-wrapper {
  position: relative;
  width: 240px;
  height: 40px;
  margin-bottom: 20px;
}

.main-content .search-box .dropdown-wrapper:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 15px;
  top: 25px;
  margin-top: -6px;
  border-width: 6px 6px 0 6px;
  border-style: solid;
  border-color: #777777 transparent;
}

.main-content .search-box .dropdown {
  width: 240px;
  height: 40px;
  padding: 4px 9px;
  border: solid 1px #ced1d2;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  color: #777777;
}

.main-content .search-box .category {
  display: none;
}

.main-content .search-box .search-btn {
  width: 165px;
  padding: 9px;
  background: #2A51AA;
  color: #FFF;
  border: none;
  border-radius: 2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  margin-top: 50px;
}

.main-content .search-box .content-search {
  width: 240px;
  height: 44px;
  padding: 12px;
  font-size: .9em;
}

.main-content .fecs-content-wrapper {
  float: left;
  width: 795px;
  height: 1550px;
  background-color: #FFF;
  margin-bottom: 100px;
}

.main-content .fecs-content-wrapper .content-buttons {
  top: -22px;
  right: 70px;
  width: 525px;
  display: block;
}

.main-content .fecs-content-wrapper .content-buttons .content-btn {
  position: absolute;
  width: 165px;
  padding: 13px 2px;
  font-size: .9em;
  margin: 0;
}

.main-content .fecs-content-wrapper .content-buttons .content-btn-1 {
  top: 0;
  left: 0;
}

.main-content .fecs-content-wrapper .content-buttons .content-btn-2 {
  top: 0;
  left: 185px;
}

.main-content .fecs-content-wrapper .content-buttons .content-btn-3 {
  top: 0;
  left: 370px;
}

.main-content .fecs-content-wrapper .content-buttons .active {
  background-color: #4AD6AE;
}

.main-content .fecs-content-wrapper .fecs-display {
  width: 100%;
  margin-top: 89px;
  padding: 18px 60px;
}

.main-content .fecs-content-wrapper .fecs-display a, .main-content .fecs-content-wrapper .fecs-display i, .main-content .fecs-content-wrapper .fecs-display span {
  color: #009B9D;
}

.main-content .fecs-content-wrapper .fecs-display h4 {
  display: table;
}

.main-content .fecs-content-wrapper .fecs-display a {
  display: table-cell;
  width: 640px;
  height: 27px;
  font-size: 20px;
  font-weight: 640;
}

.main-content .fecs-content-wrapper .fecs-display #fec_location {
  width: 610px;
  height: 26px;
  margin-top: 13px;
  margin-bottom: 22px;
}

.main-content .fecs-content-wrapper .fecs-display #fec_location span {
  display: inline-block;
  vertical-align: top;
  width: 159px;
  color: #2a51aa;
  height: 19px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}

.main-content .fecs-content-wrapper .fecs-display #fec_location img {
  display: inline-block;
  background-color: #FFF;
  width: 18px;
  height: 26px;
  margin-right: 5px;
}

.main-content .fecs-content-wrapper .fecs-display .fecs_type {
  width: 548.7px;
  height: 24px;
  font-size: 16px;
  font-style: normal;
  color: #2a51aa;
  line-height: 1.5;
  margin-bottom: 10px;
  font-weight: 600;
  font-stretch: normal;
  letter-spacing: normal;
}

.main-content .fecs-content-wrapper .fecs-display .fecs-content {
  width: 640px;
  width-height: 123px;
  border-bottom: 2px solid #4AD6AE;
  margin-left: 10px;
  margin-bottom: 30px;
  transition-property: border-bottom-color;
  transition-duration: 1s;
}

.main-content .fecs-content-wrapper .fecs-display .fecs-content:hover {
  border-bottom-color: #2a51aa;
}

.main-content .pagination-nav {
  text-align: center;
}

@media all and (max-width: 1109px) {
  .main-content .search-box {
    position: absolute;
    top: -200px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 473px;
    height: 100px;
    padding: 0;
    background-color: transparent;
  }
  .main-content .search-box h4 {
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: bold;
  }
  .main-content .search-box .search-input {
    position: absolute;
    width: 473px;
    height: 40px;
  }
  .main-content .search-box .category {
    display: none;
  }
  .main-content .search-box .content-search,
  .main-content .search-box .dropdown {
    top: 0;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 2px;
    border: none;
  }
  .main-content .search-box .content-search::-webkit-input-placeholder,
  .main-content .search-box .dropdown::-webkit-input-placeholder {
    color: #5D5D5D;
    font-size: .9em;
  }
  .main-content .search-box .content-search:-moz-placeholder,
  .main-content .search-box .dropdown:-moz-placeholder {
    color: #5D5D5D;
    opacity: 1;
    font-size: .9em;
  }
  .main-content .search-box .content-search::-moz-placeholder,
  .main-content .search-box .dropdown::-moz-placeholder {
    color: #5D5D5D;
    opacity: 1;
    font-size: .9em;
  }
  .main-content .search-box .content-search:-ms-input-placeholder,
  .main-content .search-box .dropdown:-ms-input-placeholder {
    color: #5D5D5D;
    font-size: .9em;
  }
  .main-content .search-box .content-search {
    left: 164px;
    width: 209px;
    height: 40px;
    padding: 12px;
  }
  .main-content .search-box .dropdown-wrapper {
    top: 0;
    left: 0;
    width: 200px;
  }
  .main-content .search-box .dropdown-wrapper .dropdown {
    width: 154px;
    padding: 4px 12px;
    font-size: 13px;
  }
  .main-content .search-box .dropdown-wrapper:after {
    right: 60px;
  }
  .main-content .search-box .search-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 90px;
    height: 40px;
    display: block;
    background-color: #4AD6AE;
    margin-top: 0;
    font-size: 14px;
    line-height: 14px;
    padding: 10px;
    border-radius: 2px;
  }
  .main-content .fecs-content-wrapper {
    float: none;
    width: 100%;
  }
  .main-content .fecs-content-wrapper .content-buttons {
    left: 50%;
    margin-left: -268px;
  }
  .main-content .fecs-content-wrapper .fecs-display {
    width: 100%;
    margin: 0 auto 10px;
    padding: 100px 20px 10px;
  }
  .main-content .fecs-content-wrapper .fecs-display .fecs-content {
    margin: 0 auto 13px;
    width: 95%;
  }
}

@media all and (max-width: 720px) {
  .fec-hero {
    height: 354px;
  }
  .main-content {
    margin: 0 auto 50px;
  }
  .main-content .search-box {
    top: -250px;
    width: 220px;
    height: 200px;
  }
  .main-content .search-box h4 {
    font-size: 15px;
    display: block;
  }
  .main-content .search-box .dropdown-wrapper {
    top: 0;
    left: 0;
    width: 220px;
    margin-bottom: 10px;
  }
  .main-content .search-box .dropdown-wrapper .dropdown {
    width: 220px;
    font-size: 12px;
  }
  .main-content .search-box .dropdown-wrapper:after {
    right: 15px;
  }
  .main-content .search-box .content-search {
    top: 100px;
    left: 0;
    width: 220px;
    font-size: 12px;
  }
  .main-content .search-box .search-btn {
    top: 150px;
    left: 0;
    width: 220px;
    font-size: 12px;
    line-height: 12px;
  }
  .main-content .search-box .search-input {
    margin: auto;
  }
  .main-content .search-box .category {
    display: block;
  }
  .main-content .fecs-content-wrapper {
    width: 100%;
    height: 2200px;
    margin-bottom: 0;
    margin-left: 0;
  }
  .main-content .fecs-content-wrapper .content-buttons {
    display: none;
  }
  .main-content .fecs-content-wrapper .fecs-display {
    padding: 35px 10px 10px;
  }
  .main-content .fecs-content-wrapper .fecs-content {
    width: 90% !important;
  }
}
