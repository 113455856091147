.news-detail-hero {
	@include image-formating('/assets/img/membership-hero.png');
	height: 500px; }

.slick-prev:before, .slick-next:before {
	color: rgb(74, 214, 174) !important; }

.news-detail-content {
	margin-top: 0;
	margin-bottom: 250px;

	.news-header-box {
		position: absolute;
		top: -60px;
		width: 545px;
		height: auto;
		// max-height: 190px
		background-color: $theme-green-light;
		padding: 15px 30px;

		h3 {
			color: $white;
			font-size: 25px;
			font-weight: normal;
			font-family: PingFangTC OpenSans;
			line-height: normal; }

		p {
			color: $white;
			font-size: 14px;
			margin-top: 10px;
			font-family: OpenSans; } }

	.content-header {
		top: -100px; }

	.news-detail-content-wrapper {
		width: 100%;
		padding: 200px 190px 100px;

		.news-gallery {
			margin: 0 auto 50px; } }

	// .back-to-news
	// 	position: absolute
	// 	bottom: -110px
	// 	right: 40px
	// 	width: 220px
	// 	height: 76px

	// 	img
	// 		position: absolute
	// 		top: 0
	// 		right: -40px

	// 	p
	// 		color: $theme-green

	.back-to-btn {
		display: block;
		position: absolute;
		width: 240px;
		margin: -40px auto 0;
		background-color: $theme-blue;
		padding: 14px 30px;
		margin: -25px 0 0 80px;

		span {
			left: 15px; } } }






//BREAKPOINTS
@media all and (max-width: $tablet) {
	.news-detail-hero {
		height: 320px; }

	.news-detail-content {
		.news-detail-content-wrapper {
			padding: 130px 15% 100px; }

		.back-to-news {
			right: 70px; } } }



@media all and (max-width: $mobile) {
	.news-detail-hero {
		height: 300px; }

	.news-detail-content {
		padding-bottom: 40px;
		margin-bottom: 100px;

		.news-header-box {
			top: -110px;
			left: 0;
			right: 0;
			width: 95%;
			height: 200px;
			margin: 0 auto; }

		.news-detail-content-wrapper {
			width: 100%;
			padding: 120px 20px 100px; }

		.back-to-news {
			display: none; }

		.back-to-btn {
			position: relative;
			margin: -40px auto 0; } } }
