.fec-hero {
	@include image-formating('/assets/img/membership-hero.png'); }


.main-content.no-bg-color {
	background-color: $theme-gray-light !important; }

.main-content {
	.search-box {
		float: left;
		width: 315px;
		height: 670px;
		background-color: $theme-green-light;
		padding: 65px 38px;

		h4 {
			color: $white;
			margin-bottom: 30px; }

		.dropdown-wrapper {
			position: relative;
			width: 240px;
			height: 40px;
			margin-bottom: 20px;

			&:after {
				content: "";
				width: 0;
				height: 0;
				position: absolute;
				right: 15px;
				top: 25px;
				margin-top: -6px;
				border-width: 6px 6px 0 6px;
				border-style: solid;
				border-color: #777777 transparent; } }

		.dropdown {
			width: 240px;
			height: 40px;
			padding: 4px 9px;
			border: solid 1px #ced1d2;
			border-radius: 2px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			cursor: pointer;
			outline: none;
			font-size: 14px;
			color: #777777; }

		.category {
			display: none; }

		.search-btn {
			width: 165px;
			padding: 9px;
			background: $theme-blue;
			color: $white;
			border: none;
			border-radius: 2px;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			cursor: pointer;
			outline: none;
			margin-top: 50px; }

		.content-search {
			width: 240px;
			height: 44px;
			padding: 12px;
			font-size: .9em; } }

	.fecs-content-wrapper {
		float: left;
		width: 795px;
		height: 1550px;
		background-color: $white;
		margin-bottom: 100px;

		.content-buttons {
			top: -22px;
			right: 70px;
			width: 525px;
			display: block;

			.content-btn {
				position: absolute;
				width: 165px;
				padding: 13px 2px;
				font-size: .9em;
				margin: 0; }

			.content-btn-1 {
				top: 0;
				left: 0; }

			.content-btn-2 {
				top: 0;
				left: 185px; }

			.content-btn-3 {
				top: 0;
				left: 370px; }
			.active {
				background-color: $theme-green-light; } }

		.fecs-display {
			width: 100%;
			margin-top: 89px;
			padding: 18px 60px;

			a, i, span {
				color: $theme-green; }

			h4 {
				display: table; }

			a {
				display: table-cell;
				width: 640px;
				height: 27px;
				font-size: 20px;
				font-weight: 640; }

			#fec_location {
				width: 610px;
				height: 26px;
				margin-top: 13px;
				margin-bottom: 22px;

				span {
					display: inline-block;
					vertical-align: top;
					width: 159px;
					color: #2a51aa;
					height: 19px;
					font-weight: normal;
					font-style: normal;
					font-stretch: normal;
					letter-spacing: normal; }

				img {
					display: inline-block;
					background-color: $white;
					width: 18px;
					height: 26px;
					margin-right: 5px; } }

			.fecs_type {
				width: 548.7px;
				height: 24px;
				font-size: 16px;
				font-style: normal;
				color: #2a51aa;
				line-height: 1.5;
				margin-bottom: 10px;
				font-weight: 600;
				font-stretch: normal;
				letter-spacing: normal; }

			.fecs-content {
				width: 640px {
  				height: 123px; }
				border-bottom: 2px solid $theme-green-light;
				margin-left: 10px;
				margin-bottom: 30px;
				transition-property: border-bottom-color;
				transition-duration: 1s;

				&:hover {
					border-bottom-color: #2a51aa; } } } }

	.pagination-nav {
		text-align: center; } }

//BREAKPOINTS
@media all and (max-width: $tablet) {
	.main-content {
		.search-box {
			position: absolute;
			top: -200px;
			left: 0;
			right: 0;
			margin: 0 auto;
			width: 473px;
			height: 100px;
			padding: 0;
			background-color: transparent;

			h4 {
				margin-bottom: 5px;
				font-size: 15px;
				font-weight: bold; }

			.search-input {
				position: absolute;
				width: 473px;
				height: 40px; }

			.category {
				display: none; }

			.content-search,
			.dropdown {
				top: 0;
				position: absolute;
				background: rgba(255,255,255,.8);
				border-radius: 2px;
				border: none;

				&::-webkit-input-placeholder {
					color: $theme-gray;
					font-size: .9em; }
				&:-moz-placeholder {
					color: $theme-gray;
					opacity:  1;
					font-size: .9em; }
				&::-moz-placeholder {
					color: $theme-gray;
					opacity:  1;
					font-size: .9em; }
				&:-ms-input-placeholder {
					color: $theme-gray;
					font-size: .9em; } }

			.content-search {
				left: 164px;
				width: 209px;
				height: 40px;
				padding: 12px; }

			.dropdown-wrapper {
				top: 0;
				left: 0;
				width: 200px;

				.dropdown {
					width: 154px;
					padding: 4px 12px;
					font-size: 13px; }

				&:after {
					right: 60px; } }

			.search-btn {
				position: absolute;
				top:  0;
				right: 0;
				width: 90px;
				height: 40px;
				display: block;
				background-color: $theme-green-light;
				margin-top: 0;
				font-size: 14px;
				line-height: 14px;
				padding: 10px;
				border-radius: 2px; } }

		.fecs-content-wrapper {
			float: none;
			width: 100%;

			.content-buttons {
				left: 50%;
				margin-left: -268px; }

			.fecs-display {
				width: 100%;
				margin: 0 auto 10px;
				padding: 100px 20px 10px;

				.fecs-content {
					margin: 0 auto 13px;
					width: 95%; } } } } }

@media all and (max-width: $mobile) {
	.fec-hero {
		height: 354px; }

	.main-content {
		margin: 0 auto 50px;

		.search-box {
			top: -250px;
			width: 220px;
			height: 200px;

			h4 {
				font-size: 15px;
				display: block; }

			.dropdown-wrapper {
				top: 0;
				left: 0;
				width: 220px;
				margin-bottom: 10px;

				.dropdown {
					width: 220px;
					font-size: 12px; }

				&:after {
					right: 15px; } }

			.content-search {
				top: 100px;
				left: 0;
				width: 220px;
				font-size: 12px; }

			.search-btn {
				top: 150px;
				left: 0;
				width: 220px;
				font-size: 12px;
				line-height: 12px; }

			.search-input {
				margin: auto; }

			.category {
				display: block; } }

		.fecs-content-wrapper {
			width: 100%;
			height: 2200px;
			margin-bottom: 0;
			margin-left: 0;

			.content-buttons {
				display: none; }

			.fecs-display {
				padding: 35px 10px 10px; }

			.fecs-content {
				width: 90% !important; } } } }
