.membership-hero {
	@include image-formating('/assets/img/membership-hero.png'); }


.membership-content-wrapper {
	width: 100%;
	padding: 120px 180px;

	section {
		margin-bottom: 50px;
		h3 {
			color: $theme-blue;
			margin-bottom: 25px; }

		h4 {
			color: $theme-green;
			margin-bottom: 15px; }

		p {
			margin-bottom: 20px;
			font-size: .9em; }

		ul li {
			position: relative;
			line-height: 1.8em;
			font-size: 13px;
			padding-left: 15px;
			margin-bottom: 15px;

			&:before {
				position: absolute;
				left: -1px;
				color: $theme-green;
				content: "\2022";
				font-size: 1.7em; } }

		table {
			border: solid 2px $theme-green-light;
			margin: 0 auto;

			th {
				text-align: center;
				background-color: $theme-green-light;
				color: $white;
				padding: 5px; }

			td {
				border-left: solid 2px $theme-green-light;
				padding: 7px 50px 5px 30px; }

			tr:nth-of-type(2) td {
				padding-top: 10px; }
			tr:last-of-type td {
				padding-bottom: 10px; } } }


	.become-member {
		display: inline-block;
		position: absolute;
		bottom: -25px;
		right: 100px;
		width: 300px;
		height: 55px;
		padding: 16px 20px;
		border-radius: 3px;
		background-color: $theme-blue;
		text-align: left;

		.fa-long-arrow-right {
			position: absolute;
			top: 14px;
			right: 20px;
			color: $white;
			font-size: 1.7em; } } }



//BREAKPOINTS
@media all and (max-width: $tablet) {
	.membership-content-wrapper {
		padding: 80px 70px;

		section {
			table {
				td {
					padding: 7px 10px 5px 10px; }

				tr:nth-of-type(2) td {
					padding-top: 10px; }
				tr:last-of-type td {
					padding-bottom: 10px; } } }


		.become-member {
			bottom: 20px; } } }




@media all and (max-width: $mobile) {
	.membership-hero {
		height: 200px; }

	.content-header {
		top: -35px; }

	.membership-content-wrapper {
		width: 100%;
		max-width: 500px;
		margin: 0 auto;
		padding: 40px 20px 60px;

		section {
			h3 {
				font-size: 22px;
				line-height: 25px;
				font-weight: bold; }

			h4 {
				font-size: 15px;
				line-height: 18px;
				font-weight: bold; }

			table {
				border: solid 1px $theme-green-light;

				th {
					text-align: center;
					background-color: $theme-green-light;
					color: $white;
					padding: 5px; }

				td {
					border-left: solid 1px $theme-green-light;
					padding: 7px 10px 5px 10px;
					vertical-align: top;
					line-height: 1.2em; }

				tr:nth-of-type(2) td {
					padding-top: 10px; }
				tr:last-of-type td {
					padding-bottom: 10px; } } }


		.become-member {
			bottom: 15px;
			right: 0;
			left: 0;
			width: 280px;
			margin: 0 auto;
			padding-left: 10px;

			.fa-long-arrow-right {
				right: 15px; } } } }













