

p, span, h1, h2, h3, h4, h5, li, a, label, footer, div {
	font-family: $primary-style;
	color: $theme-gray;
	line-height: 1.3em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	word-break: keep-all; }

h1 {
	font-size: 44px;
	line-height: 52px; }
h2 {
	font-size: 32px; }
h3 {
	font-size: 28px;
	line-height: 39px; }
h4 {
	font-size: 20px; }
h5 {
	font-size: 18px; }

p, span, div {
	font-size: 16px;
	font-weight: normal;
	line-height: 1.7em;
	word-break: keep-all; }


a:active,
a:focus,
a:hover {
	text-decoration: none; }

a:visited,
button:visited {
	color: $white; }


.page-container .btn,
.page-container a {
	&:active,
	&:focus,
	&:hover {
		text-decoration: none;
		color: $white; } }




//COMMON STYLES

//main content styles
.main-content {
	position: relative;
	width: 1110px;
	background-color: $white;
	margin: -80px auto 100px; }

.content-header {
	position: absolute;
	top: -40px;
	left: 2px;
	font-size: 16px;
	font-weight: bold;
	color: $white; }

.content-buttons {
	position: absolute;
	top: -20px;
	right: 40px;
	width: 810px;
	height: 70px;

	.content-btn {
		display: inline-block;
		width: 165px;
		height: 45px;
		background-color: $btn-inactive;
		color: $white;
		text-align: center;
		padding: 12px;
		color: $white;
		border-radius: 3px;
		float: left;
		margin: 0 10px;
		transition: background-color .3s;

		&:hover {
			background-color: $theme-blue; }

		&.content-btn-4 {
			width: 230px; } }

	.active-btn {
		background-color: $theme-green-light !important; } }



//end of main content styles


//pagination
.pagination-nav {
	li {
		display: inline-block;
		margin: 0 15px;

		a {
			color: $theme-gray-medium; } }

	li[rel="prev"] a,
	li[rel="next"] a {
		color: $theme-green-light;
		margin: 0 10px; }

	li.inactive a {
		display: none; }

	li.active-item a {
		color: $theme-green-light; } }

.pagination-nav-mobile {
	display: none;
	position: relative;
	width: 350px;
	margin: 0 auto;
	text-align: center;

	li {
		display: inline-block;
		width: 32px;
		height: 32px;
		margin: 0 5px;
		background-color: $theme-green;
		border: solid 2px $theme-green;

		a {
			display: inline-block;
			width: 100%;
			height: 100%;
			padding: 4px;
			color: $white;
			font-weight: bold; } }

	li[rel="prev"],
	li[rel="next"] {
		margin: 0 5px;
		background: transparent;
		border: transparent;

		a span {
			color: $theme-green; } }

	.active-item {
		background-color: $white;

		a {
			color: $theme-green; } } }



.inline {
	display: inline-block;
	vertical-align: top; }

.hidden {
	display: none !important; }

.active {
	display: block !important; }

.hero {
	width: 100%;
	height: 410px;
	position: relative;
	box-shadow: $inset-shadow; }

.block-anchor {
	display: inline-block;
	width: 100%;
	height: 100%; }

.clearfix:after {
	content: "";
	clear: both;
	display: table; }


//buttons
.btn {
	width: 155px;
	height: 50px;
	background-color: $theme-green-light;
	color: $white;
	text-align: center;
	padding: 14px;
	border-radius: 3px;

	&:hover {
		cursor: pointer; } }

.back-to-btn {
	display: none;
	position: relative;
	width: 200px;
	padding: 14px 50px;
	text-align: right;

	span {
		display: inline-block;
		position: absolute;
		top: 16px;
		left: 25px;
		font-size: 1.2em;
		color: $white; } }


//BREAKPOINTS
@media all and (max-width: $tablet) {
	.main-content {
		width: 95%; }

	// .content-header
	// 	display: none

	.content-buttons {
		display: none;
		// top: -30px
		// right: 0
		// left: 0
		// margin: 0 auto
		// width: 600px
		// height: 60px

		// .content-btn
		// 	width: 180px
		// 	height: 50px
 } }		// 	padding: 15px



@media all and (max-width: $mobile) {
	.main-content {
		width: 100%; }

	.content-header {
		display: none;
		// top: -30px
 }		// left: 20px

	.content-buttons {
		// width: 290px
		// height: 50px
		// top: -25px
		// right: 0
		// left: 0
		// margin: 0 auto
		display: none;

		.content-btn {
			width: 140px;
			height: 50px;
			padding: 14px 5px; }

		.content-btn-1 {
			margin-right: 10px; }

		.content-btn-2 {
			left: 150px; } }

	.back-to-btn {
		display: static; }

	.pagination-nav {
		display: none; }

	.pagination-nav-mobile {
		display: block; } }













