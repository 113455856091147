//edit memeber details

.edit-member-hero {
	@include image-formating('/assets/img/hero03.png');
	background-position: 50% 80%; }


.edit-member-content-wrapper {
	width: 80%;
	margin: 0 auto;
	padding: 80px 0;

	h3 {
		color: $theme-blue;
		border-bottom: solid 2px #eeeeee;
		padding-bottom: 10px;
		margin-bottom: 65px; }

	.form-wrapper {
		width: 80%;
		margin: 0 auto;

		.event-type-green {
			display: inline-block;
			float: left;
			font-size: 18px;
			color: $theme-green-light; }

		.event-type-black {
			display: inline-block;
			float: right;
			font-size: 18px;
			color: $theme-black; }

		form {
			margin-top: 50px; }

		.photo-upload-header {
			margin-bottom: 40px;

			legend {
				font-size: 18px;
				color: $theme-green-light;
				margin-bottom: 8px; } }


		.form-line {
			text-align: right;
			margin-bottom: 25px;

			label {
				display: inline-block;
				vertical-align: top;
				padding-top: 7px;
				margin-right: 10px; }


			input,
			textarea {
				width: 70%;
				height: 36px;
				font-family: $primary-style;
				color: $theme-black;
				border: solid 1px #a5a5a5;
				border-radius: 3px;
				line-height: 1.5em;
				outline: 0;
				padding: 10px;
				resize: none;
				font-size: .9rem;

				&:focus {
					border: solid 1px $theme-green-light;
					box-shadow: 0 0 1px 1px rgba(74,214,174, .5); } }

			textarea {
				height: 200px; } }


		.upload-photo {
			position: relative;
			float: left;
			width: 220px;
			height: 120px;
			background-color: #eeeeee;
			margin: 0 20px 70px 0;
			text-align: center;
			cursor: pointer;

			&:nth-of-type(2) {
				clear: left; }

			&:last-of-type {
				margin: 0 0 70px 0; }

			p {
				position: absolute;
				top: -25px; }

			.icon-add-pic {
				width: 64px;
				height: 57px;
				margin-top: 32px; }

			input[type="file"] {
				display: none; }

			img {
				width: 100%;
				height: auto; } }


		button {
			display: block;
			width: 175px;
			height: 45px;
			padding: 10px;
			border: none;
			border-radius: 3px;
			font-size: 1.1em;
			color: white;
			background-color: $theme-green-light;
			margin: 80px auto; } } }



//BREAKPOINTS
@media all and (max-width: $tablet) {
	.edit-member-hero {
		height: 270px; }

	.edit-member-content-wrapper {
		width: 85%;
		max-width: 700px;

		.form-wrapper {
			width: 100%;

			.form-line {
				margin-bottom: 15px;

				label {
					float: left; }

				input,
				textarea {
					width: 65%; } }

			.upload-photo {
				width: 184px;
				height: 108px;
				margin-right: calc((100% - (184px * 3)) / 2);

				&:last-of-type {
					margin: 0 0 70px 0; } }

			button {
				width: 125px; } } } }


@media all and (max-width: $mobile) {
	.edit-member-hero {
		height: 200px; }

	.edit-member-content-wrapper {
		width: 90%;
		padding: 20px 0 80px;

		.form-wrapper {
			width: 100%;
			max-width: 400px;

			form {
				margin-top: 35px; }

			.event-type-green {
				font-size: 14px; }

			.event-type-black {
				font-size: 14px; }

			legend {
				font-size: 16px;
				margin-bottom: 20px; }

			.form-line {
				margin-bottom: 15px;

				label {
					margin-bottom: 4px;
					font-size: 14px; }

				input,
				textarea {
					width: 100%; } }

			.member-detail-photo {
				width: 184px;
				margin: 30px auto 0; }

			.upload-photo {
				clear: both; } } } }











