.afeca-events-hero {
	@include image-formating('/assets/img/membership-hero.png'); }


.main-content.afeca {
	.afeca-events-content-wrapper {
		float: left;
		width: 100%;
		background-color: $white;
		margin-bottom: 100px;

		.content-buttons {
			top: -22px;
			right: 70px;
			width: 525px;

			.content-btn {
				position: absolute;
				width: 165px;
				height: 45px;
				padding: 13px 2px;
				font-size: .9em;
				margin: 0; }

			.content-btn-1 {
				top: 0;
				left: 0; }

			.content-btn-2 {
				top: 0;
				left: 185px; }

			.content-btn-3 {
				top: 0;
				left: 370px; }

			.active {
				background-color: $theme-green-light; } }


		.content-btn-2 {
			position: absolute;
			width: 215px;
			height: 70px;
			top: -35px;
			right: 100px;
			padding: 22px; }

		.member-display {
			width: 730px;
			margin: 120px auto 50px;
			padding: 10px 0;

			.member-box {
				width: 730px;
				height: 420px;
				margin-bottom: 40px;
				border-bottom: solid 6px $theme-blue-dark;

				.img-wrapper {
					position: relative;
					width: 332px;
					height: 414px;
					float: left;
					overflow: hidden;

					img {
						//small hack for centering the picture
						position: absolute;
						height: 100%;
						width: auto;
						top: -100%;
						bottom: -100%;
						left: -100%;
						right: -100%;
						margin: auto; } }

				.info-wrapper {
					position: relative;
					float: left;
					width: 398px;
					height: 414px;
					padding: 50px 30px 0 50px;

					.date {
						display: inline-block;
						width: 100%;
						padding: 5px 10px 10px 0;
						border-bottom: solid 1px $theme-gray-light;
						color: $theme-blue; }

					.address {
						margin: 15px 0 25px 0;

						.fa-map-marker {
							color: $theme-green-light;
							font-size: 2em;
							vertical-align: top; }
						p {
							display: inline-block;
							width: 250px;
							padding-left: 15px;
							font-size: .8em;
							color: $theme-blue;
							line-height: 1.4em; } }

					.info {
						font-size: .9em;
						line-height: 1.4em; } }


				h4 {
					color: $theme-green; }

				.full-name {
					display: block;
					color: $theme-black;
					font-size: 1.2em;
					margin-bottom: .7em; }

				.info {
					p, span {
						color: $theme-black;
						font-size: .8rem; }

					a {
						color: $theme-green; } }

				.view-detail {
					position: absolute;
					right: 0;
					bottom: 30px;
					width: 140px;
					height: 40px;
					padding: 10px;
					background-color: $theme-green; } } } }

	.pagination-nav {
		text-align: center; } }


//BREAKPOINTS
@media all and (max-width: $tablet) {
	.main-content.afeca {
		min-width: 720px;

		.afeca-events-content-wrapper {
			.content-buttons {
				right: 0; }

			.member-display {
				width: 720px;
				margin: 80px auto 50px;

				.member-box {
					width: 700px;
					margin: 0 auto 40px;

					.img-wrapper {
						width: 330px;

						img {
							height: auto;
							width: 100%; } }


					.info-wrapper {
						width: 370px;
						padding: 50px 15px 0 20px; } } } } } }



@media all and (max-width: $mobile) {
	.main-content.afeca {
		min-width: 320px; }

	.afeca-events-hero {
		height: 300px; }

	.main-content.afeca {
		background-color: $theme-gray-light;

		.afeca-events-content-wrapper {
			width: 100%;
			height: auto;
			background-color: $theme-gray-light;

			.content-btn-2 {
				top: -35px;
				left: 0;
				right: 0;
				margin: 0 auto; }

			.member-display {
				width: 100%;
				max-width: 300px;
				margin: 30px auto 50px;

				.member-box {
					width: 100%;
					height: auto;
					background-color: $white;

					.img-wrapper {
						width: 100%;
						height: 330px;

						img {
							height: auto;
							width: 100%; } }

					.info-wrapper {
						width: 100%;
						height: auto;
						padding: 20px;

						.date {
							padding: 5px 10px 0 15px;
							border-bottom: solid 2px $theme-gray-light; }

						.address {
							p {
								width: 230px; } }

						.info {
							margin-bottom: 80px; } }

					.view-detail {
						left: 0;
						right: 0;
						margin: 0 auto; } } } } } }
