.news-room-hero {
	@include image-formating('/assets/img/membership-hero.png'); }

.news-room-content {
	height: 210px;
	overflow-y: hidden; }

.news-room-content-wrapper {
	position: relative;
	width: 1110px;
	margin: -80px auto 100px;

	.content-header {
		position: absolute;
		top: -40px;
		left: 2px; }

	.item-wrapper {
		width: 100%; }

	.item-wrapper>a {
		float: left;

		&:nth-of-type(3n+2) {
			margin-left: 30px;
			margin-right: 30px; } }

	.item-wrapper>a {
		margin-bottom: 40px;
		border-bottom: solid 6px $theme-green-light; }

	.item-wrapper>a:hover {
		border-bottom: solid 6px $theme-blue; }



	.item-wrapper>a>div {
		position: relative;
		width: 350px;
		height: 400px;
		padding: 30px 35px;
		background-color: $white;

		h6 {
			display: inline-block;
			width: 110%;
			margin-left: -15px;
			margin-bottom: 20px;
			padding: 5px 10px 10px 15px;
			border-bottom: solid 1px $theme-gray-light;
			color: $theme-green; }

		p {
			color: $theme-black;
			font-size: .85em;
			line-height: 1.4em; }

		.date {
			position: absolute;
			display: inline-block;
			right: 35px;
			bottom: 20px;
			color: $theme-gray-medium;
			font-size: .9em; }

		.fa-file-pdf-o {
			display: inline-block;
			margin-bottom: 20px;
			color: $theme-green-light;
			font-size: 3em; } }


	.pagination-nav {
		text-align: center; } }



//BREAKPOINTS
@media all and (max-width: $tablet) {
	.news-room-hero {
		height: 320px; }

	.news-room-content-wrapper {
		width: 100%;

		.content-header {
			left: 12%; }

		.item-wrapper {
			width: 730px;
			margin: 0 auto;

			a:nth-of-type(3n+2) {
				margin-left: 0;
				margin-right: 0; }

			a:nth-of-type(2n+2) {
				margin-left: 30px; } }


		// .item-wrapper>a>div
 } }		// 	margin: 0 10px 40px



@media all and (max-width: $mobile) {
	.news-room-content-wrapper {
		width: 100%;

		.item-wrapper {
			width: 320px;

			a:nth-of-type(2n+2) {
				margin-left: 0; } }

		.item-wrapper>a>div {
			max-width: 320px;
			height: 430px;
			margin: 0 0 40px; }

		.item-wrapper>a {
			margin: 0; } } }






// .news-room-content-wrapper
// 	position: relative
// 	width: 1110px
// 	margin: -60px auto 100px

// 	.content-header
// 		position: absolute
// 		top: -40px
// 		left: 2px

// 	.item-wrapper
// 		display: flex
// 		flex-wrap: wrap
// 		justify-content: space-between
// 		align-content: flex-start

// 	.item-wrapper>a>div
// 		position: relative
// 		flex: 1 1 auto
// 		width: 350px
// 		height: 400px
// 		margin-bottom: 40px
// 		padding: 30px 35px
// 		background-color: $white
// 		border-bottom: solid 6px $theme-green-light

// 		h6
// 			display: inline-block
// 			width: 110%
// 			margin-left: -15px
// 			margin-bottom: 20px
// 			padding: 5px 10px 10px 15px
// 			border-bottom: solid 3px $theme-gray-light
// 			color: $theme-green

// 		p
// 			color: $theme-black
// 			font-size: .85em
// 			line-height: 1.4em

// 		.date
// 			position: absolute
// 			display: inline-block
// 			right: 35px
// 			bottom: 20px
// 			color: $theme-gray-medium
// 			font-size: .9em

// 		.fa-file-pdf-o
// 			display: inline-block
// 			margin-bottom: 20px
// 			color: $theme-green-light
// 			font-size: 3em


// 	.pagination-nav
// 		text-align: center
