
.index-hero {
	position: relative;
	width: 100%;
	height: 700px;
	box-shadow: $inset-shadow;

	.hero-toggle {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 700px;
		box-shadow: $inset-shadow; }

	.index-hero-1 {
		@include image-formating('/assets/img/index-hero-2-m.png');
		animation-name: picture-toggle;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-duration: 16s;
		animation-delay: 11s; }

	.index-hero-2 {
		@include image-formating('/assets/img/hero01.png');
		animation-name: picture-toggle;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-duration: 16s;
		animation-delay: 7s; }

	.index-hero-3 {
		@include image-formating('/assets/img/hero02.png');
		animation-name: picture-toggle;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-duration: 16s;
		animation-delay: 3s; }

	.index-hero-4 {
		@include image-formating('/assets/img/hero03.jpg');
		animation-name: picture-toggle-first;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-duration: 16s;
		animation-delay: 0s; }

	.index-hero-5 {
		@include image-formating('/assets/img/hero20170526glbexbd.jpg');
		animation-name: picture-toggle-first;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-duration: 16s;
		animation-delay: 14s; } }



@keyframes picture-toggle-first {
	0% {
		opacity: 1; }
	18.75% {
		opacity: 1; }
	25% {
		opacity: 0; }
	93.75% {
		opacity: 0; }
	100% {
		opacity: 1; } }

@keyframes picture-toggle {
	0% {
		opacity: 1; }
	25% {
		opacity: 1; }
	31.25% {
		opacity: 0; }
	93.75% {
		opacity: 0; }
	100% {
		opacity: 1; } }



.slogan {
	position: absolute;
	top: 225px;
	left: 0;
	right: 0;
	width: 1100px;
	margin: 0 auto;

	h1 {
		margin-bottom: 30px; }

	h1, h2 {
		color: $white;
		text-shadow: $text-shadow;
		font-weight: bold; }

	.btn {
		position: absolute;
		top: 140px;
		left: 0;
		right: 0;
		margin: 0 auto;
		padding: 14px 10px; } }


.welcome-wrapper {
	position: relative;
	width: 1110px;
	height: 430px;
	margin: -150px auto 100px;
	padding: 40px 30px 40px 50px;
	background-color: $white;
	z-index: 2;

	.welcome {
		width: 458px;
		float: left;

		h3 {
			margin-bottom: 1em;
			color: $theme-blue;
			font-weight: bold; }

		p {
			font-size: 18px;
			line-height: 26px; } }


	.upcoming-events {
		width: 485px;
		float: left;

		h3 {
			margin-bottom: 1.2em;
			color: $theme-teal;
			font-weight: bold; }

		.event-row {
			margin-bottom: 25px;

			h5 {
				font-weight: bold; }

			div {
				display: inline-block;
				float: left; }

			.event-date {
				width: 30%;

				.date {
					display: block;
					font-size: .8em;
					color: $theme-gray-medium; } }

			.event-info {
				width: 70%;

				p {
					font-size: .9em;
					color: $theme-gray-medium; } } } }


	.vertical-line {
		width: 2px;
		height: 300px;
		float: left;
		margin: 10px 35px 0 47px;
		background-color: $theme-gray-light; }

	.see-more {
		display: inline-block;
		position: absolute;
		right: 56px;
		bottom: 20px;
		color: $theme-green-light; } }


.latest-news-wrapper {
	position: relative;
	width: 1110px;
	margin: 120px auto 80px;
	padding: 20px 0 20px;

	.news-list {
		float: left;
		width: 46%;

		h3 {
			position: absolute;
			top: -50px;
			margin-bottom: 2em;
			color: $theme-blue;
			font-weight: bold; }

		.news-item {
			width: 100%;
			margin-bottom: 40px;

			h5 {
				color: $theme-green;
				font-size: 15px;
				margin-bottom: 5px;
				font-weight: bold; }

			.date {
				display: block;
				width: 100%;
				font-size: 14px;
				color: $theme-gray-medium;
				border-bottom: solid 1px #dee8e8;
				margin-bottom: 7px; }

			.description {
				font-size: 13px;
				color: $theme-black; } } }


	.company-video {
		position: relative;
		float: right;
		width: 504px;
		height: 320px;
		margin-left: 30px;

		iframe {
			width: 504px;
			height: 284px; } }

	.btn {
		position: absolute;
		right: 130px;
		bottom: -30px;
		width: 220px;
		height: 45px;
		font-family: $bold-theme;
		font-size: 15px;
		padding: 12px;
		font-weight: bold;
		transition: background-color .3s;

		&:hover {
			background-color: $theme-blue; } } }

.index-events-photo-wrap {
	position: relative;
	width: 1124px;
	min-height: 100px;
	height: auto;
	overflow: visible;
	margin: 90px auto 60px;

	.album-wrap {
		width: 1124px;
		min-height: 360px;

		.single-event {
			width: 360px;
			height: 360px;
			float: left;
			overflow: visible;
			margin: 0px 7px;

			.img-wrap {
				width: 348px;
				height: 195px;
				position: relative;
				overflow: visible;
				&:after {
					content: "";
					width: 348px;
					height: 195px;
					background-color: #2a51aa;
					top: 12px;
					left: 12px;
					position: absolute;
					display: block;
					z-index: 0;
					@include smooth(0.2s, linear); }

				img {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 348px;
					height: 195px;
					display: block;
					z-index: 1;
					@include smooth(0.2s, linear); }

				&:hover {
					// img
					// 	top: -12px
					// 	left: -12px
					&:after {
						top: 0px;
						left: 0px; } }

				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 348px;
					height: 195px;
					display: block;
					z-index: 1; } }

			.title {
				font-size: 20px;
				font-weight: 600;
				line-height: 1.3;
				color: #009b9d;
				margin-top: 35px; }

			.time {
				font-size: 16px;
				font-weight: 600;
				line-height: 1.19;
				color: #2951aa;
				margin-top: 14px; } } }

	.btn {
		width: 240px;
		height: 45px;
		font-family: $bold-theme;
		font-size: 15px;
		padding: 12px;
		font-weight: bold;
		transition: background-color .3s;

		&:hover {
			background-color: $theme-blue; } } }

.partner-wrapper-outer {
	width: 100%;
	min-height: 630px;
	background-color: $white;
	margin-bottom: 100px;
	padding: 35px 0;
	overflow: hidden;

	.partner-title {
		font-family: OpenSans;
		font-weight: bold;
		color: #2a51aa;
		font-size: 24px;
		width: 100%;
		margin-left: 6px; }


	.partner-wrapper-inner {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 1110px;
		margin: 0 auto;

		div {
			position: relative;
			background-color: $white;
			margin: 10px auto;
			overflow: hidden;

			a img {
				position: absolute;
				height: auto;
				width: 100%;
				top: -100%;
				bottom: -100%;
				left: -100%;
				right: -100%;
				margin: auto; } }


		div.small {
			width: 210px;
			height: 145px; }

		div.big {
			width: 360px;
			height: 145px; } } }




//BREAKPOINTS
@media all and (max-width: $tablet) {
	.index-hero {
		height: 375px;

		.hide-for-mobile {
			display: none; } }


	.index-hero-mobile {
		@include image-formating('/assets/img/hero01.png');
		height: 375px;
		z-index: 1; }


	.slogan {
		width: 95%;
		top: 155px;
		padding: 0 20px;
		margin: 0 auto;
		text-align: center;

		h1 {
			font-size: 36px;
			margin-bottom: 0; }
		h2 {
			font-size: 18px; } }


	.welcome-wrapper {
		width: 95%;
		height: auto;
		margin: -70px auto 20px;
		padding: 35px 60px 40px;

		.welcome {
			width: 100%;
			float: none;
			margin-bottom: 60px;

			h3 {
				margin-bottom: 1em; } }

		.upcoming-events {
			width: 100%;
			float: none;

			h3 {
				margin: 1.1em 0; } }

		.see-more {
			right: 56px;
			bottom: 10px; }

		.vertical-line {
			display: none; } }


	.latest-news-wrapper {
		width: 95%;
		height: auto;
		margin: 80px auto;
		padding: 20px 60px;

		.news-list {
			float: none;
			width: 600px;
			padding: 0; }

		.company-video {
			float: none;
			width: 560px;
			hight: 315px;
			margin: 50px 0;

			iframe {
				width: 560px;
				height: 315px; }

			.btn {
				bottom: -60px; } } }

	.index-events-photo-wrap {
		width: 95%;
		height: auto;
		margin: 80px auto;
		padding: 20px 60px;

		.album-wrap {
			width: 100%;
			min-height: 435px; } }

	.partner-wrapper-outer {
		width: 100%;
		height: auto;
		margin-bottom: 100px;
		padding: 25px 15px;

		.partner-wrapper-inner {
			width: 95%;
			margin: 0 auto; } } }



@media all and (max-width: $mobile) {
	.index-hero-mobile,
	.index-hero {
		height: 200px; }

	.slogan {
		top: 80px;

		h1 {
			font-size: 21px;
			line-height: 23px;
			margin-bottom: 10px; }
		h2 {
			font-size: 16px; } }


	.welcome-wrapper {
		width: 100%;
		margin: 0 auto 50px;
		padding: 40px 20px;

		.welcome {
			width: 100%;
			float: none;
			margin-bottom: 20px;

			h3 {
				font-size: 20px;
				line-height: 23px;
				margin-bottom: 1em; }

			p {
				font-size: 14px;
				line-height: 21px; }

			.btn {
				margin-bottom: 22px; } }


		.upcoming-events {
			width: 100%;
			float: none;

			h3 {
				font-size: 20px;
				line-height: 23px;
				margin: 1.1em 0; }

			.event-row {
				.event-date {
					width: 100%;
					margin-bottom: 5px;

					.date {
						display: inline; } }

				.event-info {
					width: 100%; } } }


		.see-more {
			right: 20px; } }


	.latest-news-wrapper {
		margin: 80px auto;
		padding: 0;

		.news-list {
			float: none;
			width: 100%;
			padding: 0 20px;

			h3 {
				font-size: 20px;
				line-height: 23px; } }


		.company-video {
			float: none;
			width: 90%;
			max-width: 500px;
			height: 250px;
			margin: 40px auto;

			iframe {
				width: 100%;
				height: 250px; }

			.btn {
				left: 0;
				margin: 0 auto; } } }


	.index-events-photo-wrap {
		width: 95%;
		margin: 0 auto;
		padding: 0;

		.album-wrap {
			width: 100%;

			.single-event {
				float: initial;
				width: 100%;
				max-width: 500px;
				height: 250px;
				margin: 100px auto;

				.img-wrap {
					width: 100%;
					height: 240px;
					position: relative;
					overflow: visible;
					&:after {
						content: "";
						width: 100%;
						height: 240px;
						background-color: #2a51aa;
						top: 10px;
						left: 10px;
						position: absolute;
						display: block;
						z-index: 0;
						@include smooth(0.2s, linear); }

					img {
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 240px;
						display: block;
						z-index: 1;
						@include smooth(0.2s, linear); }

					&:hover {
						img {
							top: -10px;
							left: -10px; }
						&:after {
							top: 0px;
							left: 0px; } }

					iframe {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 240px;
						display: block;
						z-index: 1; } } } } }

	.partner-wrapper-outer {
		width: 100%;
		height: auto;
		background-color: $white;
		margin-bottom: 100px;
		padding: 25px 20px;

		.partner-wrapper-inner {
			position: relative;
			width: 100%;
			margin: 0 auto;

			h4 {
				margin-bottom: 40px; }

			a {
				position: static;
				display: block;
				width: 100%;
				margin: 0 auto 30px;
				text-align: center; } } } }
