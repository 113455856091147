.board-members-hero {
	@include image-formating('/assets/img/board-member-hero.png'); }

.board-members-content-wrapper {
	padding: 34px 95px;

	.row {
		border-bottom: solid 1px $theme-gray-medium;

		.member {
			float: left;
			width: 450px;

			img {
				width: 108px;
				height: 108px;
				float: left;
				margin: 40px 20px 40px 0;
				border-radius: 50%;
				background-color: hsl(0, 0%, 90%); }

			.p-4 {
				padding: 4px; } }

		.member-description {
			width: 300px;
			height: 180px;
			float: left;
			padding-top: 55px;

			span {
				display: inline-block;
				font-size: 14px;
				color: $theme-gray-medium;
				vertical-align: top; }

			span.name {
				font-weight: bold;
				font-size: 18px;
				color: #141413;
				line-height: 26px; }

			span.title {
				color: $theme-blue;
				margin-bottom: 6px; }

			span p {
				line-height: 1.2em;
				font-size: 14px;
				color: $theme-gray-medium; } } }


	.row-1 {
		.member {
			width: 600px; } }

	.row-6,
	.advisory-council {
		border-bottom: none; } }




//BREAKPOINTS
@media all and (max-width: $tablet) {
	.board-members-content-wrapper {
		padding: 30px;

		.row {
			width: 100%;
			max-width: 750px;
			margin: 0 auto;

			.member {
				width: 50%; }

			.member-description {
				width: 180px;
				height: 180px; } } } }




@media all and (max-width: $mobile) {
	.board-members-hero {
		height: 200px; }

	.board-members-content-wrapper {
		padding: 34px 15px;

		.content-header {
			top: -35px; }

		.row {
			max-width: 500px;
			margin-bottom: 20px;

			.member {
				float: none;
				margin: 0 auto;
				width: 100%;

				img {
					width: 75px;
					height: 75px;
					margin: 10px 15px 40px 0; } }

			.member-description {
				width: calc(100% - 90px);//full width with deducted img width and margin
				height: 130px;
				padding-top: 10px;
				overflow: hidden;

				.name {
					font-weight: bold;
					color: $theme-black; } } } } }
